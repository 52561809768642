import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../common/Title"
import { useContext } from "react"
import {
  defaultBoxShadow,
  desktopColumnsGap,
  desktopMaxWidth,
  desktopPadding,
  navBarHeight,
} from "../../../services/config/constants"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../../controllers/main"
import menuWhiteIcon from "../../../assets/icons/menu-white.svg"
import partnerPoweredByAWorldLogo from "../../../assets/images/partner-powered-by-aworld.png"
import CountUp from "react-countup"

const NavBarDesktopTutorial = () => {
  const { t } = useTranslation()
  const { user, viewTutorial } = useContext(MainContext)

  return (
    <nav
      role="navigation"
      style={{
        width: "100%",
        height: window.location.pathname === "/" ? 177 : navBarHeight,
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          window.location.pathname === "/"
            ? "rgba(0, 0, 0, 0)"
            : colors.background,
        transition:
          window.location.pathname === "/"
            ? "height 200ms, background-color 200ms"
            : "height 200ms, background-color 0ms",
      }}
    >
      <img
        src={partnerPoweredByAWorldLogo}
        style={{
          height: 62,
          position: "absolute",
          opacity: window.location.pathname === "/" ? 1 : 0,
          transition: "100ms",
          zIndex: 99,
        }}
        alt={t("partner_logo")}
        aria-hidden={window.location.pathname === "/" ? "false" : "true"}
      />
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: `calc(100% - (${desktopPadding} * 2))`,
          maxWidth: desktopMaxWidth,
          height: 60,
          gap: desktopColumnsGap,
          position: "relative",
        }}
        role="presentation"
      >
        {/* menu */}
        <div
          style={{
            width: 185,
            height: 60,
            borderRadius: 30,
            backgroundColor: colors.backgroundWhite,
            position: "absolute",
            top: 0,
            transition: "250ms",
            transitionTimingFunction: "cubic-bezier(.17, .8, 0.2, 1.15)",
            alignItems: "flex-start",
            boxShadow: defaultBoxShadow,
            zIndex: 99,
          }}
          role="presentation"
        >
          {/* open and close button */}
          <ButtonBase
            disableRipple
            style={{
              width: "100%",
              height: 60,
              position: "absolute",
              justifyContent: "flex-start",
              paddingLeft: 9,
              borderRadius: 30,
              zIndex: 1,
            }}
          >
            <div
              className="center"
              style={{
                width: 46,
                height: 46,
                borderRadius: "100%",
                backgroundColor: colors.primary,
              }}
            >
              <img
                src={menuWhiteIcon}
                style={{
                  width: 26,
                  position: "absolute",
                }}
                alt=""
              />
            </div>
          </ButtonBase>
          {/* current page */}
          <Title
            fontSize={18}
            lineHeight="46px"
            color={colors.primary}
            style={{
              transition: "200ms",
              position: "absolute",
              top: 7,
              left: 67,
            }}
          >
            {t("home")}
          </Title>
        </div>
        {/* performance button */}
        <ButtonBase
          id="navbar-performance-button"
          style={{
            width: 185,
            height: "100%",
            borderRadius: 30,
            backgroundColor: colors.backgroundWhite,
            position: "absolute",
            right: 0,
            boxShadow: defaultBoxShadow,
            zIndex: 99,
          }}
          aria-label={t("performance")}
        >
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "100%",
              height: "100%",
              paddingLeft: 9,
            }}
          >
            <div
              style={{
                width: 46,
                height: 46,
                borderRadius: "100%",
                position: "relative",
                zIndex: 1,
                backgroundImage: `url(${
                  user!.profileImage
                }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
              }}
            />
            {viewTutorial &&
            document.getElementById("navbar-performance-button")?.style
              .zIndex === "150" ? (
              <Stack
                direction="row"
                gap={0.5}
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: 18,
                }}
              >
                <CountUp
                  start={user?.points}
                  end={356}
                  delay={0.2}
                  duration={10}
                  style={{
                    fontSize: `${18 * 0.063}rem`,
                    fontWeight: 700,
                    color: colors.primary,
                  }}
                />
                <Title fontSize={18} color={colors.primary}>
                  {t("points")}
                </Title>
              </Stack>
            ) : (
              <Title
                fontSize={18}
                color={colors.primary}
                style={{
                  transition: "400ms",
                  position: "absolute",
                  zIndex: 1,
                  right: 18,
                }}
              >
                {`${user?.points} ${t("points")}`}
              </Title>
            )}
          </Stack>
        </ButtonBase>
      </Stack>
    </nav>
  )
}

export default NavBarDesktopTutorial
