import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { get } from "../services/api/api"
import Category from "../models/category"
import {
  parseCategoriesListData,
  parseMainCategoriesListData,
} from "../services/utils/parseFunctions"
import { MainContext } from "./main"
import MainCategory from "../models/mainCategory"

interface TagsContextInterface {
  categoriesLoading: boolean
  categoriesError: boolean
  categories: Category[]
  mainCategoriesLoading: boolean
  mainCategoriesError: boolean
  mainCategories: MainCategory[]
}

const TagsContext = createContext<TagsContextInterface>({
  categoriesLoading: true,
  categoriesError: false,
  categories: [],
  mainCategoriesLoading: true,
  mainCategoriesError: false,
  mainCategories: [],
})

const TagsController = ({ children }: { children: ReactNode }) => {
  const { lang } = useContext(MainContext)

  // loadings
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true)
  const [mainCategoriesLoading, setMainCategoriesLoading] =
    useState<boolean>(true)

  // errors
  const [categoriesError, setCategoriesError] = useState<boolean>(false)
  const [mainCategoriesError, setMainCategoriesError] = useState<boolean>(false)

  // states
  const [categories, setCategories] = useState<Category[]>([]) // all categories list
  const [mainCategories, setMainCategories] = useState<MainCategory[]>([]) // all main categories list

  // get categories list
  const getCategories = async () => {
    setCategoriesLoading(true)
    setCategoriesError(false)

    try {
      const { data } = await get("/web/category/list")

      // parse data
      parseCategoriesListData(data.items, lang)
      console.log("categories list", data.items)

      setCategories(data.items)
      setCategoriesLoading(false)
    } catch (e) {
      console.log("categories list error", e)
      setCategoriesLoading(false)
      setCategoriesError(true)
    }
  }

  // get main categories list
  const getMainCategories = async () => {
    setMainCategoriesLoading(true)
    setMainCategoriesError(false)

    try {
      const { data } = await get("/web/maincategory/list")

      // parse data
      parseMainCategoriesListData(data.items, lang)
      console.log("main categories list", data.items)

      setMainCategories(data.items)
      setMainCategoriesLoading(false)
    } catch (e) {
      console.log("categories list error", e)
      setMainCategoriesLoading(false)
      setMainCategoriesError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    getCategories()
    getMainCategories()
  }, [])

  return (
    <TagsContext.Provider
      value={{
        categoriesLoading,
        categoriesError,
        categories,
        mainCategoriesLoading,
        mainCategoriesError,
        mainCategories,
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}
export { TagsController, TagsContext }
