import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import AppearFromSide from "../animations/AppearFromSide"
import { useContext, useState } from "react"
import {
  defaultBoxShadow,
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
  withPrizes,
} from "../../services/config/constants"
import ActionDetailsBackgroundDesktop from "../../assets/svgComponents/ActionDetailsBackgroundDesktop"
import propicsRowImage from "../../assets/images/propics-row.png"
import { MainContext } from "../../controllers/main"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import { ChallengeContext } from "../../controllers/challenge"
import Text from "../global/common/Text"
import { scrollWindow, scrollWindowToTop } from "../../services/utils/utils"
import ChallengeProgressBar from "./common/ChallengeProgressBar"
import months from "../../services/config/months"
import LeaderboardListItem from "../performance/common/LeaderboardListItem"
import { UsersContext } from "../../controllers/users"
import { useNavigate } from "react-router-dom"
import Button from "../global/common/Button"
import SavingCard from "../actions/common/SavingCard"
import Appear from "../animations/Appear"
import AvatarInfoButton from "../global/desktop/AvatarInfoButton"
import AlertInfo from "../global/common/AlertInfo"

const ChallengeDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight, userGroup, prizes } = useContext(MainContext)
  const { challenge, challengeSavings } = useContext(ChallengeContext)
  const { leaderboard } = useContext(UsersContext)

  // what is challenge info alert
  const [challengeInfoAlertOpen, setChallengeInfoAlertOpen] =
    useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight:
          windowHeight > 1400 ? `calc(100% - ${footerHeight}px)` : 1400,
        position: "relative",
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("challenge"),
          },
        ]}
        selectedItem={0}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* header */}
          <Stack
            style={{
              position: "relative",
              marginTop: 22,
            }}
          >
            <AppearFromSide fade>
              <Title fontSize={36} lineHeight="46px" component="h1">
                {t("collective_challenge").charAt(0).toUpperCase() +
                  t("collective_challenge").slice(1).toLowerCase()}
              </Title>
            </AppearFromSide>
            <AppearFromSide fade delay={0.05}>
              <Text
                fontSize={28}
                lineHeight="46px"
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {t("help_achieve_common_goal")}
              </Text>
            </AppearFromSide>
            <Appear style={{ position: "absolute", right: 0 }} fade>
              <AvatarInfoButton
                title={t("what_is_the_challenge")}
                onClick={() => {
                  setChallengeInfoAlertOpen(true)
                }}
              />
            </Appear>
          </Stack>
          {/* content */}
          <div
            style={{
              width: "100%",
              marginTop: 117,
              marginBottom: 58,
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* challenge card */}
            <AppearFromSide
              fade
              delay={0.1}
              style={{ width: "48.57%", height: "auto" }}
            >
              <Stack
                style={{
                  width: "100%",
                  height: "auto",
                  padding: 46,
                  backgroundColor: colors.backgroundWhite,
                  boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                  borderRadius: 10,
                }}
              >
                <Title fontSize={36} lineHeight="46px" component="h2">
                  {challenge!.title}
                </Title>
                <Title
                  fontSize={24}
                  lineHeight="46px"
                  style={{ marginTop: 26 }}
                  component="h4"
                >
                  {t("goal")}
                </Title>
                <Stack
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: 21,
                    marginTop: 16,
                    position: "relative",
                  }}
                >
                  <Text fontSize={18} lineHeight="21px" fontWeight={400}>
                    {challenge!.currentAmount} {t("missions").toLowerCase()}
                  </Text>
                  <Text
                    fontSize={18}
                    lineHeight="21px"
                    fontWeight={700}
                    style={{ position: "absolute", right: 0 }}
                  >
                    {challenge!.targetAmount} {t("missions").toLowerCase()}
                  </Text>
                </Stack>
                <ChallengeProgressBar
                  percentage={Math.round(
                    (challenge!.currentAmount / challenge!.targetAmount) * 100
                  )}
                  style={{ marginTop: 8 }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%", height: 26, marginTop: 22, gap: 14 }}
                >
                  <img src={propicsRowImage} style={{ height: 26 }} alt="" />
                  <Text fontSize={16} fontWeight={400}>
                    {challenge!.userCount} {t("partecipants")}
                  </Text>
                </Stack>
                <Title
                  fontSize={24}
                  lineHeight="46px"
                  style={{ marginTop: 26 }}
                  component="h4"
                >
                  {t("description")}
                </Title>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    width: "100%",
                    height: 70,
                    gap: desktopColumnsGap,
                    marginTop: 16,
                  }}
                >
                  <Stack
                    alignItems="center"
                    style={{
                      width: "50%",
                      height: "100%",
                      borderRadius: 10,
                      backgroundColor: colors.background,
                      paddingTop: 11,
                      gap: 4,
                    }}
                  >
                    <Text fontSize={16}>{t("starts")}</Text>
                    <Text fontSize={18} fontWeight={700}>
                      {`${new Date(challenge!.startDate).getDate()} ${t(
                        months[new Date(challenge!.startDate).getMonth()]
                      ).toLowerCase()} ${new Date(
                        challenge!.startDate
                      ).getFullYear()}`}
                    </Text>
                  </Stack>
                  <Stack
                    alignItems="center"
                    style={{
                      width: "50%",
                      height: "100%",
                      borderRadius: 10,
                      backgroundColor: colors.background,
                      paddingTop: 11,
                      gap: 4,
                    }}
                  >
                    <Text fontSize={16}>{t("ends")}</Text>
                    <Text fontSize={18} fontWeight={700}>
                      {`${new Date(challenge!.endDate).getDate()} ${t(
                        months[new Date(challenge!.endDate).getMonth()]
                      ).toLowerCase()} ${new Date(
                        challenge!.endDate
                      ).getFullYear()}`}
                    </Text>
                  </Stack>
                </Stack>
                <div
                  className="html"
                  dangerouslySetInnerHTML={{
                    __html: challenge!.description.replace(/<\/?a[^>]*>/g, ""),
                  }}
                  style={{
                    fontSize: 20,
                    fontWeight: 300,
                    lineHeight: "32px",
                    color: colors.text,
                    overflow: "hidden",
                    marginTop: 20,
                  }}
                />
                {challengeSavings ? (
                  <Title
                    fontSize={24}
                    lineHeight="46px"
                    style={{ marginTop: 26 }}
                    component="h4"
                  >
                    {t("community_savings")}
                  </Title>
                ) : null}
                {challengeSavings &&
                (challengeSavings.co2Saving ||
                  challengeSavings.waterSaving ||
                  challengeSavings.energySaving) ? (
                  <Stack
                    direction="row"
                    className="hide-scrollbars"
                    gap={2}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      overflowX: "scroll",
                      marginTop: 16,
                    }}
                  >
                    {challengeSavings.co2Saving ? (
                      <SavingCard
                        metric="co2"
                        amount={challengeSavings.co2Saving}
                      />
                    ) : null}
                    {challengeSavings.waterSaving ? (
                      <SavingCard
                        metric="water"
                        amount={challengeSavings.waterSaving}
                      />
                    ) : null}
                    {challengeSavings.energySaving ? (
                      <SavingCard
                        metric="energy"
                        amount={challengeSavings.energySaving}
                      />
                    ) : null}
                  </Stack>
                ) : null}
                <Button
                  title={t("contribute_to_the_challenge")}
                  width="100%"
                  style={{ marginTop: 42 }}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/")
                    setTimeout(() => {
                      scrollWindow(780, "smooth")
                    }, 250)
                  }}
                >
                  {t("contribute_to_the_challenge")}
                </Button>
              </Stack>
            </AppearFromSide>
            {/* side content */}
            <AppearFromSide
              fade
              delay={0.1}
              x={100}
              style={{ width: "40%", height: "auto", marginTop: 350 }}
            >
              {/* prizes */}
              {withPrizes && (
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ position: "relative" }}
                  >
                    <Title
                      fontSize={26}
                      color={colors.primary}
                      style={{ fontStyle: "italic" }}
                    >
                      {t("prizes").toUpperCase()}
                    </Title>
                  </Stack>
                  <Stack direction="row" style={{ marginTop: 16, gap: 20 }}>
                    {prizes.map((prize) => (
                      <Stack
                        key={prize.title}
                        style={{
                          width: 200,
                          height: 287,
                          borderRadius: 12,
                          boxShadow: defaultBoxShadow,
                          backgroundColor: colors.backgroundWhite,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: 150,
                            backgroundImage: `url(${prize.image})`,
                            backgroundSize: "122%",
                            backgroundPosition: "70%",
                            position: "relative",
                          }}
                        >
                          <div
                            className="center"
                            style={{
                              width: "fit-content",
                              height: 25,
                              backgroundColor: colors.primary,
                              borderRadius: 22,
                              paddingInline: 11,
                              paddingTop: 3,
                              position: "absolute",
                              left: 15,
                              bottom: 13,
                            }}
                          >
                            <Text
                              fontSize={14}
                              fontWeight={500}
                              color={colors.textWhite}
                            >
                              {prize.heading}
                            </Text>
                          </div>
                        </div>
                        <Stack style={{ padding: 16, gap: 12 }}>
                          <Title
                            fontSize={18}
                            lineHeight="25px"
                            style={{ height: 75 }}
                          >
                            {prize.title}
                          </Title>
                          <Title fontSize={14} fontWeight={400}>
                            {prize.value}
                          </Title>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )}
              {/* leaderboard */}
              <Stack
                style={{
                  width: "100%",
                  height: "auto",
                  marginTop: withPrizes ? 66 : 0,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%", height: 36, position: "relative" }}
                >
                  <Title fontSize={26}>{t("leaderboard")}</Title>
                  {leaderboard.length > 5 ? (
                    <ButtonBase
                      style={{ position: "absolute", right: 0, bottom: 3 }}
                      aria-label={t("see_all")}
                      onClick={() => {
                        navigate("/performance/leaderboard")
                      }}
                    >
                      <Text
                        fontSize={16}
                        fontWeight={700}
                        color={colors.primary}
                        style={{ textDecoration: "underline" }}
                      >
                        {t("see_all")}
                      </Text>
                    </ButtonBase>
                  ) : null}
                </Stack>
                <Stack style={{ width: "100%", marginTop: 16, gap: 16 }}>
                  {leaderboard.length ? (
                    leaderboard
                      .slice(0, 5)
                      .map((item, index) => (
                        <LeaderboardListItemCard
                          key={index}
                          place={index + 1}
                          name={item.name}
                          points={item.points}
                          isUserGroup={item.groupId === userGroup}
                        />
                      ))
                  ) : (
                    <Text fontSize={18} fontWeight={400}>
                      {t("leaderboard_empty_state")}.
                    </Text>
                  )}
                </Stack>
              </Stack>
            </AppearFromSide>
          </div>
        </Stack>
      </PaddingContainerDesktop>
      {/* background */}
      <div
        style={{
          width: "100%",
          maxWidth: 1920,
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ActionDetailsBackgroundDesktop
          color={colors.background}
          style={{ position: "absolute", top: 900 }}
        />
      </div>
      {/* challenge image */}
      <img
        src={challenge!.image}
        style={{
          width: "100%",
          height: 337,
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          top: 335,
        }}
        alt=""
        aria-hidden="true"
      />
      {/* alerts */}
      <AlertInfo
        open={challengeInfoAlertOpen}
        setOpen={setChallengeInfoAlertOpen}
        title={t("what_is_the_challenge")}
        description={t("challenge_description_no_html", {
          count: challenge?.targetAmount,
        })}
        descriptionAlign="center"
      />
    </Stack>
  )
}

const LeaderboardListItemCard = ({
  place,
  name,
  points,
  isUserGroup,
}: {
  place: number
  name: string
  points: number
  isUserGroup: boolean
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: 72,
        backgroundColor: colors.backgroundWhite,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <LeaderboardListItem
        place={place}
        name={name}
        points={points}
        isUserGroup={isUserGroup}
      />
    </div>
  )
}

export default ChallengeDesktop
