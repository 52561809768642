import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  alwaysShowOnboarding,
  availableLanguages,
  mobileBreakpoint,
} from "../services/config/constants"
import i18next, { t } from "i18next"
import { checkToken, get, post, put } from "../services/api/api"
import {
  parseAvatarsListData,
  parseUserData,
} from "../services/utils/parseFunctions"
import User from "../models/user"
import { useNavigate, useSearchParams } from "react-router-dom"
import Group from "../models/group"
import Avatar from "../models/avatar"
import { cacheImages } from "../services/utils/utils"
import Mission from "../models/mission"
import Prize from "../models/prize"
import prize1Image from "../assets/images/prizes/prize-1.jpg"
import prize2Image from "../assets/images/prizes/prize-2.png"

interface MainContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  signUpError: boolean
  viewOnboarding: boolean
  viewTutorial: boolean
  setViewTutorial: Dispatch<SetStateAction<boolean>>
  viewGroupSelection: boolean
  viewAvatarSelection: boolean
  setViewAvatarSelection: Dispatch<SetStateAction<boolean>>
  setSignUpError: Dispatch<SetStateAction<boolean>>
  setViewOnboarding: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  windowWidth: number
  windowHeight: number
  lang: string
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
  userGroup: string | null
  userError: boolean
  groupsError: boolean
  getUserInfo: (firstCall?: boolean) => Promise<boolean>
  setUserFirstAccess: () => Promise<boolean>
  signUp: () => void
  groupsList: Group[]
  changeUserGroup: (groupId: string) => void
  changeLang: (newLang: string) => Promise<boolean>
  visualizingErrorPage: boolean
  setVisualizingErrorPage: Dispatch<SetStateAction<boolean>>
  visualizingLoadingPage: boolean
  setVisualizingLoadingPage: Dispatch<SetStateAction<boolean>>
  currentTutorialPage: number
  setCurrentTutorialPage: Dispatch<SetStateAction<number>>
  avatars: Avatar[]
  changeAvatar: (newAvatar: string) => Promise<boolean>
  currentMission: Mission | null
  setCurrentMission: Dispatch<SetStateAction<Mission | null>>
  updatingMissions: boolean
  setUpdatingMissions: Dispatch<SetStateAction<boolean>>
  prizes: Prize[]
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  setLoading: () => {},
  signUpError: false,
  viewOnboarding: true,
  viewTutorial: true,
  setViewTutorial: () => {},
  viewGroupSelection: true,
  viewAvatarSelection: true,
  setViewAvatarSelection: () => {},
  setSignUpError: () => {},
  setViewOnboarding: () => {},
  isMobile: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  lang: "en",
  user: null,
  setUser: () => {},
  userGroup: null,
  userError: false,
  groupsError: false,
  getUserInfo: async () => true,
  setUserFirstAccess: async () => true,
  signUp: () => {},
  groupsList: [],
  changeUserGroup: () => {},
  changeLang: async () => false,
  visualizingErrorPage: false,
  setVisualizingErrorPage: () => {},
  visualizingLoadingPage: false,
  setVisualizingLoadingPage: () => {},
  currentTutorialPage: 0,
  setCurrentTutorialPage: () => {},
  avatars: [],
  changeAvatar: async () => true,
  currentMission: null,
  setCurrentMission: () => {},
  updatingMissions: false,
  setUpdatingMissions: () => {},
  prizes: [],
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // loadings
  const [loading, setLoading] = useState<boolean>(true) // main loading
  const [updatingMissions, setUpdatingMissions] = useState<boolean>(true) // loading for missions update

  // states
  const [signUpError, setSignUpError] = useState<boolean>(false) // signin error
  const [viewOnboarding, setViewOnboarding] = useState<boolean>(true) // view or not sign up onboarding
  const [viewGroupSelection, setViewGroupSelection] = useState<boolean>(true) // view or not team group selection
  const [viewAvatarSelection, setViewAvatarSelection] = useState<boolean>(true) // view or not user avatar selection
  const [viewTutorial, setViewTutorial] = useState<boolean>(true) // view or not post sign up onboarding
  const [isMobile, setIsMobile] = useState<boolean>(false) // if screen is mobile size or not
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // window current width
  const [windowHeight, setWindowHeight] = useState<number>(
    window.innerHeight - 50
  ) // window current height
  const [lang, setLang] = useState<string>("en") // app language
  const [user, setUser] = useState<User | null>(null) // current user
  const [userGroup, setUserGroup] = useState<string | null>(null) // current user group
  const [userError, setUserError] = useState<boolean>(false) // current user error
  const [groupsError, setGroupsError] = useState<boolean>(false) // team groups error
  const [groupsList, setGroupsList] = useState<Group[]>([]) // team groups list
  const [visualizingErrorPage, setVisualizingErrorPage] =
    useState<boolean>(false) // if user is visualizing error page or not
  const [visualizingLoadingPage, setVisualizingLoadingPage] =
    useState<boolean>(false) // if user is visualizing loading page or not
  const [currentTutorialPage, setCurrentTutorialPage] = useState<number>(0) // current tutorial page
  const [avatars, setAvatars] = useState<Avatar[]>([]) // avatars list
  const [currentMission, setCurrentMission] = useState<Mission | null>(
    localStorage.getItem("currentMission")
      ? JSON.parse(localStorage.getItem("currentMission")!)
      : null
  ) // current mission
  const prizes: Prize[] = useMemo(
    () => [
      {
        heading: `1° ${t("in_ranking").toLowerCase()}`,
        title: t("prize_1_title"),
        image: prize1Image,
        value: t("prize_1_value"),
      },
      {
        heading: `2° - 10° ${t("in_ranking").toLowerCase()}`,
        title: t("prize_2_title"),
        image: prize2Image,
        value: t("prize_2_value"),
      },
    ],
    [lang]
  ) // array of prizes

  // change language
  const changeLang = async (newLang: string) => {
    try {
      await put("/web/user/update", { lang: newLang })

      return true
    } catch (e) {
      console.log(e)

      return false
    }
  }

  // get user
  const getUser = async () => {
    const { data } = await get("/web/user/get")

    return data
  }

  // get user wallet
  const getUserWallet = async () => {
    const { data } = await get("/web/mission/point/user")

    return data
  }

  // get all user info
  const getUserInfo = async (firstCall = false) => {
    setUserError(false)

    try {
      const result = await Promise.all([
        getUser(),
        getUserWallet(),
        getAvatars(),
      ])

      const userData = result[0]
      const userWallet = result[1]

      // parse data
      parseUserData(userData)
      if (userWallet.points) {
        userData.points = userWallet.points
      } else {
        userData.points = 0
      }

      console.log("user", userData)
      setUser(userData)

      // set app language based on user language
      if (userData.lang) {
        i18next.changeLanguage(userData.lang)
        if (availableLanguages.find((item) => item.code === userData.lang)) {
          setLang(userData.lang)
          document.documentElement.lang = userData.lang
        } else {
          setLang("en")
          document.documentElement.lang = "en"
        }
      }

      // don't show avatar selection if the user has already one set
      if (userData.profileImage && !alwaysShowOnboarding) {
        setViewAvatarSelection(false)
      }
      if (firstCall) {
        if (!userData.firstAccess && !alwaysShowOnboarding) {
          setViewTutorial(false)
        } else {
          navigate("/")
        }
      }

      return true
    } catch (e) {
      console.log("user error", e)
      setUserError(true)

      return false
    }
  }

  // get avatars list
  const getAvatars = async () => {
    try {
      const { data } = await get("/web/user/avatar/list")
      const dataToSet = parseAvatarsListData(data)
      console.log("avatars list", dataToSet)

      // cache avatars
      await cacheImages(dataToSet.map((item) => item.url))

      setAvatars(dataToSet)

      return true
    } catch (e) {
      console.log("avatars list error", e)
      return false
    }
  }

  // change user avatar
  const changeAvatar = async (newAvatar: string) => {
    try {
      await put("/web/user/profileimage", { profileImage: newAvatar })
      console.log(`avatar set ${newAvatar}`)

      // update user locally
      user!.profileImage = newAvatar
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("profile image change error", e)

      return false
    }
  }

  // set user first access to false
  const setUserFirstAccess = async () => {
    try {
      await put("/web/user/firstaccess")
      console.log("user firstAccess set to false")

      // set first access to false locally
      user!.firstAccess = false
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("firstaccess error", e)
      setUserError(true)

      return false
    }
  }

  // get team groups list
  const getGroupsList = async () => {
    try {
      const { data } = await get("/team/group/list")
      console.log("team groups", data)

      setGroupsList(data)

      return true
    } catch (e) {
      console.log(e)
      setGroupsError(true)

      return false
    }
  }

  // get group to which the user belongs
  const getUserGroup = async () => {
    try {
      const { data } = await get("/team/group/user")

      console.log("user group", data.group)
      if (data.group && data.group.groupId) {
        setUserGroup(data.group.groupId)
      }

      if (data.group && data.group.groupId && !alwaysShowOnboarding) {
        setViewGroupSelection(false)
      } else {
        // get team groups list
        await getGroupsList()
      }

      return true
    } catch (e) {
      console.log(e)
      setGroupsError(true)

      return false
    }
  }

  // set user group
  const changeUserGroup = async (groupId: string) => {
    try {
      await put("/team/group/user", { groupId })
      console.log("group set", groupId)

      setUserGroup(groupId)
      setLoading(false)
      setViewGroupSelection(false)
    } catch (e) {
      console.log(e)
      setGroupsError(true)
    }
  }

  // check if screen is mobile or not
  useEffect(() => {
    // first check
    if (window.innerWidth >= mobileBreakpoint) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    // event listener on resize
    window.addEventListener("resize", () => {
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }

      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight - 50)
    })
  }, [])

  // signup
  const signUp = async () => {
    try {
      // get key and iv from query params
      const key = searchParams.get("key")
      const iv = searchParams.get("iv")

      // do signup
      if (
        alwaysShowOnboarding &&
        localStorage.getItem("accessToken") &&
        localStorage.getItem("refreshToken")
      ) {
        // get current user and user group
        await Promise.all([getUserInfo(true), getUserGroup()])
      } else {
        const { data } = await post(
          "/signup",
          {},
          {
            key: key,
            iv: iv,
          },
          false
        )

        // set tokens to local storage
        localStorage.setItem("accessToken", data.AccessToken)
        localStorage.setItem("refreshToken", data.RefreshToken)

        // get current user and user group
        await Promise.all([getUserInfo(true), getUserGroup()])
      }

      setViewOnboarding(false)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSignUpError(true)
    }
  }

  // check if an auth session is already present or not
  const checkSession = async () => {
    if (alwaysShowOnboarding) {
      setLoading(false)
      return
    }

    try {
      if (
        !localStorage.getItem("accessToken") ||
        !localStorage.getItem("refreshToken")
      ) {
        throw new Error("No auth session found")
      }

      const result = await checkToken()
      if (!result) {
        setSignUpError(true)
        return
      }

      // get current user and user group
      await Promise.all([getUserInfo(true), getUserGroup()])

      setViewOnboarding(false)
      setLoading(false)
    } catch {
      // no user, so go to onboarding, accept terms and privacy and do signup
      setLoading(false)
    }
  }

  // initial fetch
  useEffect(() => {
    // get language from query param and set it
    const langFromParams = searchParams.get("lang")
    if (langFromParams) {
      i18next.changeLanguage(langFromParams)
      if (availableLanguages.some((item) => item.code === langFromParams)) {
        document.documentElement.lang = langFromParams
        setLang(langFromParams)
      } else {
        document.documentElement.lang = "en"
        setLang("en")
      }
    }

    // get key and iv from params, show error page if one of them or both are missing and no token is present
    const key = searchParams.get("key")
    const iv = searchParams.get("iv")
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")
    if ((!accessToken || !refreshToken) && (!key || !iv)) {
      setSignUpError(true)
      return
    }

    checkSession()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContext.Provider
      value={{
        loading,
        setLoading,
        signUpError,
        viewOnboarding,
        viewTutorial,
        setViewTutorial,
        viewGroupSelection,
        viewAvatarSelection,
        setViewAvatarSelection,
        setSignUpError,
        setViewOnboarding,
        isMobile,
        windowWidth,
        windowHeight,
        lang,
        user,
        setUser,
        userGroup,
        userError,
        groupsError,
        getUserInfo,
        setUserFirstAccess,
        signUp,
        groupsList,
        changeUserGroup,
        changeLang,
        visualizingErrorPage,
        setVisualizingErrorPage,
        visualizingLoadingPage,
        setVisualizingLoadingPage,
        currentTutorialPage,
        setCurrentTutorialPage,
        avatars,
        changeAvatar,
        currentMission,
        setCurrentMission,
        updatingMissions,
        setUpdatingMissions,
        prizes,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
