import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import GroupSelectionMobile from "./GroupSelectionMobile"
import Group from "../../models/group"

const GroupSelectionDesktop = ({
  selectedGroup,
  setSelectedGroup,
}: {
  selectedGroup: Group | null
  setSelectedGroup: Dispatch<SetStateAction<Group | null>>
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <GroupSelectionMobile
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        height="100%"
        continueButtonWidth={330}
      />
    </Stack>
  )
}

export default GroupSelectionDesktop
