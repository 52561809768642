import { Stack } from "@mui/material"
import { CSSProperties, useContext } from "react"
import { colors } from "../../../services/config/colors"
import walkInNatureImage from "../../../assets/images/walk-in-nature.png"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { Trans, useTranslation } from "react-i18next"
import { FootprintContext } from "../../../controllers/footprint"

const FootprintCompensateCardDesktop = ({
  style,
}: {
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { userFootprint } = useContext(FootprintContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 118,
        backgroundColor: colors.background,
        borderRadius: 10,
        paddingInline: 20,
        gap: 42,
        ...style,
      }}
    >
      <img src={walkInNatureImage} style={{ width: 78, height: 78 }} alt="" />
      <Stack style={{ width: 300, gap: 8 }}>
        <Title fontSize={20}>{t("did_you_know")}</Title>
        <Text fontSize={18} fontWeight={400}>
          <Trans
            i18nKey="footprint_compensate"
            count={Math.round((userFootprint!.footprint * 1000) / 22)}
          />
        </Text>
      </Stack>
    </Stack>
  )
}

export default FootprintCompensateCardDesktop
