import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerPoweredByAWorldLogo from "../../assets/images/partner-powered-by-aworld.png"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { useTranslation } from "react-i18next"
import Alert from "../global/common/Alert"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import Group from "../../models/group"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import backgroundImage from "../../assets/images/onboarding-background.jpg"

const GroupSelectionMobile = ({
  selectedGroup,
  setSelectedGroup,
  height,
  continueButtonWidth,
}: {
  selectedGroup: Group | null
  setSelectedGroup: Dispatch<SetStateAction<Group | null>>
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const { windowHeight, isMobile, groupsList, changeUserGroup } =
    useContext(MainContext)

  // loading for team selection
  const [loading, setLoading] = useState<boolean>(false)

  // something is missing alert
  const [missingAlertOpen, setMissingAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundImage: isMobile ? "none" : `url(${backgroundImage})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: 550,
          minHeight: 550,
          overflow: "hidden",
          paddingTop: 26,
        }}
      >
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
          }}
          alignItems="center"
        >
          {/* header */}
          <FadeFromTop>
            <img
              src={partnerPoweredByAWorldLogo}
              style={{ height: 42 }}
              alt={t("partner_logo_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {t("your_group")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("your_group_description")}
            </Text>
          </FadeFromTop>
          <FadeFromTop
            delay={0.15}
            style={{
              width: "100%",
              maxWidth: onboardingElementsMaxWidth,
              marginTop: 60,
            }}
          >
            <Stack style={{ width: "100%", gap: 12 }} role="radiogroup">
              {groupsList.map((group) => (
                <GroupRadioItem
                  key={group.groupId}
                  group={group}
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                  disabled={loading}
                />
              ))}
            </Stack>
          </FadeFromTop>
        </Stack>
      </Stack>
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "100%",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          loading={loading}
          title={t("continue")}
          width={continueButtonWidth ?? "calc(100% - 32px)"}
          onClick={() => {
            if (selectedGroup) {
              setLoading(true)
              changeUserGroup(selectedGroup.groupId)
            } else {
              setMissingAlertOpen(true)
            }
          }}
        >
          {t("continue")}
        </Button>
      </FadeFromBottom>
      {/* missing alert */}
      <Alert
        open={missingAlertOpen}
        title={t("group_missing_alert_title")}
        description={t("group_missing_alert_description")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setMissingAlertOpen(false)
        }}
      />
    </Stack>
  )
}

const GroupRadioItem = ({
  group,
  selectedGroup,
  setSelectedGroup,
  disabled,
}: {
  group: Group
  selectedGroup: Group | null
  setSelectedGroup: Dispatch<SetStateAction<Group | null>>
  disabled: boolean
}) => {
  return (
    <ButtonBase
      disabled={disabled}
      style={{
        width: "100%",
        height: 56,
        minHeight: 56,
        borderRadius: 16,
        backgroundColor: colors.backgroundGray,
        border:
          selectedGroup && selectedGroup.groupId === group.groupId
            ? "2px solid " + colors.primary
            : "2px solid rgba(0, 0, 0, 0)",
        transition: "150ms",
      }}
      onClick={() => {
        setSelectedGroup(group)
      }}
      role="radio"
      aria-checked={
        selectedGroup && selectedGroup.groupId === group.groupId
          ? "true"
          : "false"
      }
    >
      <Title fontSize={16}>{group.name}</Title>
    </ButtonBase>
  )
}

export default GroupSelectionMobile
