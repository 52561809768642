import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import GroupSelectionMobile from "../../components/onboarding/GroupSelectionMobile"
import Group from "../../models/group"
import GroupSelectionDesktop from "../../components/onboarding/GroupSelectionDesktop"

const GroupSelection = () => {
  const { isMobile } = useContext(MainContext)

  // selected group
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)

  return isMobile ? (
    <GroupSelectionMobile
      selectedGroup={selectedGroup}
      setSelectedGroup={setSelectedGroup}
    />
  ) : (
    <GroupSelectionDesktop
      selectedGroup={selectedGroup}
      setSelectedGroup={setSelectedGroup}
    />
  )
}

export default GroupSelection
