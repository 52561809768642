import itFlagImage from "../../assets/images/flags/it.svg"
import enFlagImage from "../../assets/images/flags/en.svg"
import esFlagImage from "../../assets/images/flags/es.svg"

// config
export const shoudlShowApiLogs = false
export const alwaysShowOnboarding = false
export const maximumActionsPerTime = 10
export const mobileBreakpoint = 1280
export const accessibilityHeightThreshold = 350
export const onboardingElementsMaxWidth = 550
export const availableLanguages = [
  { code: "it", label: "italian", flag: itFlagImage },
  { code: "en", label: "english", flag: enFlagImage },
  { code: "es", label: "spanish", flag: esFlagImage },
]
export const withPrizes = false

// stories
export const numberOfRows = 43
export const numberOfColumns = 15
export const slideWidth = 360
export const slideHeight = 700

// dimensions
export const navBarHeight = 90
export const footerHeight = 106
export const desktopPadding = "8.984vw"
export const desktopMaxWidth = 1050
export const desktopActionsLogButtonWidth = "39%"
export const desktopActionsLogButtonMaxWidth = 557
export const desktopColumnsGap = 30

// data
export const italianFootprintAverage = 5.72

// utils
export const defaultBoxShadow = "0px 0px 24px rgba(17, 67, 97, 0.08)"
export const defaultBoxShadowDarker = "0px 0px 24px rgba(17, 67, 97, 0.16)"
