import { createContext, ReactNode, useEffect, useState } from "react"
import { deleteMethod, get } from "../services/api/api"
import { parseGroupData } from "../services/utils/parseFunctions"
import Group from "../models/group"

interface UsersContextInterface {
  leaderboardLoading: boolean
  leaderboardUpdating: boolean
  leaderboardError: boolean
  leaderboard: Group[]
  leaderboardNextToken: string | null
  getLeaderboard: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  deleteUser: () => Promise<boolean>
}

const UsersContext = createContext<UsersContextInterface>({
  leaderboardLoading: true,
  leaderboardUpdating: false,
  leaderboardError: false,
  leaderboard: [],
  leaderboardNextToken: null,
  getLeaderboard: async () => true,
  deleteUser: async () => true,
})

const UsersController = ({ children }: { children: ReactNode }) => {
  // loadings
  const [leaderboardLoading, setLeaderboardLoading] = useState<boolean>(true)
  const [leaderboardUpdating, setLeaderboardUpdating] = useState<boolean>(true)

  // errors
  const [leaderboardError, setLeaderboardError] = useState<boolean>(false)

  // states
  const [leaderboard, setLeaderboard] = useState<Group[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<
    string | null
  >(null)

  // get leaderboard
  const getLeaderboard = async (withLoading = true, withNextToken = true) => {
    if (withLoading) {
      setLeaderboardLoading(true)
    }
    setLeaderboardUpdating(true)
    setLeaderboardError(false)

    try {
      const { data } = await get(
        leaderboardNextToken && withNextToken
          ? `/web/mission/point/leaderboard?nextToken=${leaderboardNextToken}`
          : "/web/mission/point/leaderboard"
      )

      // parse data
      data.walletGroupsLeaderboardList.items.forEach((item: any) => {
        parseGroupData(item)
      })

      if (leaderboardNextToken && withNextToken) {
        console.log("leaderboard", [
          ...leaderboard,
          ...data.walletGroupsLeaderboardList.items,
        ])
        setLeaderboard((current) => [
          ...current,
          ...data.walletGroupsLeaderboardList.items,
        ])
      } else {
        console.log("leaderboard", data.walletGroupsLeaderboardList.items)
        setLeaderboard(data.walletGroupsLeaderboardList.items)
      }
      setLeaderboardNextToken(data.walletGroupsLeaderboardList.nextToken)

      setLeaderboardLoading(false)
      setLeaderboardUpdating(false)

      return true
    } catch (e) {
      console.log("leaderboard error", e)
      setLeaderboardError(true)
      setLeaderboardUpdating(false)

      return false
    }
  }

  // delete user
  const deleteUser = async () => {
    try {
      await deleteMethod("/web/user/delete")

      return true
    } catch (e) {
      console.log("user delete error", e)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getLeaderboard()
  }, [])

  return (
    <UsersContext.Provider
      value={{
        leaderboardLoading,
        leaderboardUpdating,
        leaderboardError,
        leaderboard,
        leaderboardNextToken,
        getLeaderboard,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}
export { UsersController, UsersContext }
