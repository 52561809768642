import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import Action from "../models/action"
import HistoryItem from "../models/historyItem"
import { get, post } from "../services/api/api"
import { registerEvent } from "../services/utils/utils"
import {
  parseActionsListData,
  parseDetailedHistoryData,
  parseHistoryData,
} from "../services/utils/parseFunctions"
import HistoryItemDetailed from "../models/historyItemDetailed"
import { MainContext } from "./main"
import { MissionsContext } from "./missions"

interface HistoryAvailability {
  lastAvailablePeriod: {
    month: number
    year: number
  }
  firstUnavailablePeriod: {
    month: number
    year: number
  }
}

interface ActionsContextInterface {
  actionsLoading: boolean
  actionsError: boolean
  actions: Action[]
  setActions: Dispatch<SetStateAction<Action[]>>
  selectedActions: Action[]
  setSelectedActions: Dispatch<SetStateAction<Action[]>>
  currentDate: Date
  setCurrentDate: Dispatch<SetStateAction<Date>>
  getActionsList: () => void
  logActions: (actionsToLog?: Action[]) => void
  logActionsLoading: boolean
  currentHistory: HistoryItem[]
  setCurrentHistory: Dispatch<SetStateAction<HistoryItem[]>>
  historyLoading: boolean
  historyError: boolean
  detailedHistoryLoading: boolean
  detailedHistoryError: boolean
  getCurrentActionsHistory: () => void
  currentCarouselPage: number
  setCurrentCarouselPage: Dispatch<SetStateAction<number>>
  currentDetailedHistory: HistoryItemDetailed[]
  getActionsDetailedHistory: (date: Date) => Promise<boolean>
  selectedCategory: string
  setSelectedCategory: Dispatch<SetStateAction<string>>
  historyAvailability: HistoryAvailability
  actionsPerTimeLimitAlertOpen: boolean
  setActionsPerTimeLimitAlertOpen: Dispatch<SetStateAction<boolean>>
}

const ActionsContext = createContext<ActionsContextInterface>({
  actionsLoading: true,
  actionsError: false,
  actions: [],
  setActions: () => {},
  selectedActions: [],
  setSelectedActions: () => {},
  currentDate: new Date(),
  setCurrentDate: () => {},
  getActionsList: () => {},
  logActions: () => {},
  logActionsLoading: false,
  currentHistory: [],
  setCurrentHistory: () => {},
  historyLoading: true,
  historyError: false,
  detailedHistoryLoading: true,
  detailedHistoryError: false,
  getCurrentActionsHistory: () => {},
  currentCarouselPage: 0,
  setCurrentCarouselPage: () => {},
  currentDetailedHistory: [],
  getActionsDetailedHistory: async () => true,
  selectedCategory: "catDefault",
  setSelectedCategory: () => {},
  historyAvailability: {
    lastAvailablePeriod: {
      month: 0,
      year: 0,
    },
    firstUnavailablePeriod: {
      month: 0,
      year: 0,
    },
  },
  actionsPerTimeLimitAlertOpen: false,
  setActionsPerTimeLimitAlertOpen: () => {},
})

const ActionsController = ({ children }: { children: ReactNode }) => {
  const { lang, setUpdatingMissions } = useContext(MainContext)
  const { updateAll } = useContext(MissionsContext)

  // loadings
  const [actionsLoading, setActionsLoading] = useState<boolean>(true) // loading for actions list
  const [logActionsLoading, setLogActionsLoading] = useState<boolean>(false) // loading for actions logging
  const [historyLoading, setHistoryLoading] = useState<boolean>(true) // loading for history
  const [detailedHistoryLoading, setDetailedHistoryLoading] =
    useState<boolean>(true) // loading for detailed history

  // errors
  const [actionsError, setActionsError] = useState<boolean>(false) // actions list error
  const [historyError, setHistoryError] = useState<boolean>(false) // history error
  const [detailedHistoryError, setDetailedHistoryError] =
    useState<boolean>(false) // detailed history error

  // states
  const [actions, setActions] = useState<Action[]>([]) // all actions list
  const [selectedActions, setSelectedActions] = useState<Action[]>([]) // list of selected actions
  const [currentDate, setCurrentDate] = useState<Date>(new Date()) // selected date to show history of
  const [currentHistory, setCurrentHistory] = useState<HistoryItem[]>([]) // history of current month
  const [currentDetailedHistory, setCurrentDetailedHistory] = useState<
    HistoryItemDetailed[]
  >([]) // history of current day
  const [currentCarouselPage, setCurrentCarouselPage] = useState<number>(0) // selected dot for actions carousel
  const [selectedCategory, setSelectedCategory] = useState<string>("catDefault") // current selected category for filter
  const [historyAvailability, setHistoryAvailability] =
    useState<HistoryAvailability>({
      lastAvailablePeriod: {
        month: 0,
        year: 0,
      },
      firstUnavailablePeriod: {
        month: 0,
        year: 0,
      },
    }) // last month available for calendar (user can view max 365 days of history)
  const [actionsPerTimeLimitAlertOpen, setActionsPerTimeLimitAlertOpen] =
    useState<boolean>(false) // alert for when the user tries to select more than the maximum loggable actions per time

  // get actions list
  const getActionsList = async () => {
    setActionsLoading(true)
    setActionsError(false)

    try {
      let nextToken
      let items = []

      do {
        let result
        result = await get(
          nextToken
            ? "/web/action/list?nextToken=" + nextToken
            : "/web/action/list"
        )
        items.push(...result.data.items)
        nextToken = result.data.nextToken
      } while (nextToken)

      console.log(`actions list`, items)

      // parse data
      parseActionsListData(items, lang)

      setActions(items)

      setActionsLoading(false)
    } catch (e: any) {
      console.log("actions list error", e)
      setActionsLoading(false)
      setActionsError(true)
    }
  }

  // log actions
  const logActions = async (actionsToLog?: Action[]) => {
    setUpdatingMissions(true)

    const actionsToLogArray =
      actionsToLog && actionsToLog.length ? actionsToLog : selectedActions
    console.log("actions logged", actionsToLogArray)

    // update current history locally
    const currentHistoryCopy: HistoryItem[] = JSON.parse(
      JSON.stringify(currentHistory)
    )

    const todayDate = new Date().toLocaleDateString()
    actionsToLogArray.forEach((action) => {
      if (
        currentHistoryCopy.some(
          (item) => new Date(item.date).toLocaleDateString() === todayDate
        )
      ) {
        const historyItemToUpdate = currentHistoryCopy.find(
          (item) => new Date(item.date).toLocaleDateString() === todayDate
        )
        if (
          historyItemToUpdate?.counters.some(
            (item) => item.category.id === action.category.id
          )
        ) {
          historyItemToUpdate!.counters.find(
            (item) => item.category.id === action.category.id
          )!.amount += 1
        } else {
          historyItemToUpdate!.counters.push({
            amount: 1,
            category: {
              id: action.category.id,
              name: action.category.name,
              backColor: action.category.backColor,
              backTagColor: action.category.backTagColor,
              foreColor: action.category.foreColor,
            },
          })
        }
      } else {
        currentHistoryCopy.push({
          date: new Date().toISOString().split("T")[0],
          counters: [
            {
              amount: 1,
              category: {
                id: action.category.id,
                name: action.category.name,
                backColor: action.category.backColor,
                backTagColor: action.category.backTagColor,
                foreColor: action.category.foreColor,
              },
            },
          ],
        })
      }
    })

    setCurrentHistory(currentHistoryCopy)

    // update current detailed history locally
    actionsToLogArray.forEach((action) => {
      currentDetailedHistory.push({
        createdAt: new Date().toISOString(),
        action: action,
      })
    })

    setCurrentDetailedHistory([...currentDetailedHistory])

    // register google analytics event
    actionsToLogArray.forEach((action) => {
      registerEvent("log_action", {
        action: action.id,
      })
    })

    // empty selected actions array
    setSelectedActions([])

    // api call
    try {
      await post("/web/action/log", {
        actions: actionsToLogArray.map((action) => {
          return {
            actionId: action.id,
            categoryId: action.category.id,
          }
        }),
      })

      // update
      updateAll()
    } catch (e: any) {
      console.log("log actions error", e)
    }
  }

  // get current actions history (current month + last 11 months)
  const getCurrentActionsHistory = async () => {
    setHistoryLoading(true)
    setHistoryError(false)

    try {
      const calls = []
      let lastMonthCalled: number
      let lastYearCalled: number
      for (let i = 0; i < 12; i++) {
        const currentDateCopy = new Date(currentDate)
        currentDateCopy.setDate(1)
        const date = new Date(
          currentDateCopy.setMonth(currentDateCopy.getMonth() - i)
        )

        lastMonthCalled = date.getMonth()
        lastYearCalled = date.getFullYear()

        calls.push(
          get(
            `/web/action/history/month/${date.getFullYear()}-${(
              date.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}`
          )
        )
      }

      const firstUnavailableDate = new Date(
        new Date(currentDate).setMonth(new Date(currentDate).getMonth() - 12)
      )

      setHistoryAvailability({
        lastAvailablePeriod: { month: lastMonthCalled!, year: lastYearCalled! },
        firstUnavailablePeriod: {
          month: firstUnavailableDate.getMonth(),
          year: firstUnavailableDate.getFullYear(),
        },
      })

      const result = await Promise.all(calls)
      const arrayToSet: any[] = []
      result.forEach((resultItem) => {
        arrayToSet.push(...resultItem.data.items)
      })

      parseHistoryData(arrayToSet)
      console.log(`year history`, arrayToSet)

      setCurrentHistory(arrayToSet)

      setHistoryLoading(false)
    } catch (e: any) {
      console.log("current history error", e)
      setHistoryLoading(false)
      setHistoryError(true)
    }
  }

  // get detailed actions history
  const getActionsDetailedHistory = async (date: Date) => {
    setDetailedHistoryError(false)

    try {
      const dateToUse = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        12,
        0,
        0
      )

      let nextToken
      let items = []

      do {
        let result
        result = await get(
          nextToken
            ? `/web/action/history/day/${
                dateToUse.toISOString().split("T")[0]
              }?nextToken=` + nextToken
            : `/web/action/history/day/${dateToUse.toISOString().split("T")[0]}`
        )
        items.push(...result.data.items)
        nextToken = result.data.nextToken
      } while (nextToken)

      // parse data
      parseDetailedHistoryData(items, lang)
      console.log(
        `${dateToUse.getDate()}/${
          dateToUse.getMonth() + 1
        }/${dateToUse.getFullYear()} detailed history`,
        items
      )

      setCurrentDetailedHistory(items)
      setDetailedHistoryLoading(false)

      return true
    } catch (e: any) {
      console.log("current detailed history error", e)
      setDetailedHistoryLoading(false)
      setDetailedHistoryError(true)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getActionsList()
    getCurrentActionsHistory()
    getActionsDetailedHistory(new Date())
  }, [])

  return (
    <ActionsContext.Provider
      value={{
        actionsLoading,
        actionsError,
        actions,
        setActions,
        selectedActions,
        setSelectedActions,
        currentDate,
        setCurrentDate,
        getActionsList,
        logActions,
        logActionsLoading,
        currentHistory,
        setCurrentHistory,
        historyLoading,
        historyError,
        detailedHistoryLoading,
        detailedHistoryError,
        getCurrentActionsHistory,
        currentCarouselPage,
        setCurrentCarouselPage,
        currentDetailedHistory,
        getActionsDetailedHistory,
        selectedCategory,
        setSelectedCategory,
        historyAvailability,
        actionsPerTimeLimitAlertOpen,
        setActionsPerTimeLimitAlertOpen,
      }}
    >
      {children}
    </ActionsContext.Provider>
  )
}
export { ActionsController, ActionsContext }
