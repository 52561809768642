import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import MainCategory from "../../../models/mainCategory"
import { FootprintSector } from "../../../services/config/enum"
import { useContext } from "react"
import { FootprintContext } from "../../../controllers/footprint"
import { useTranslation } from "react-i18next"

const FootprintAnswersCardMobile = ({
  width,
  mainCategory,
  sector,
  givenAnswers,
  totalAnswers,
}: {
  width: number
  mainCategory: MainCategory
  sector: FootprintSector
  givenAnswers: number
  totalAnswers: number
}) => {
  const { t } = useTranslation()
  const { formsList } = useContext(FootprintContext)

  return (
    <ButtonBase
      aria-label={t("complete")}
      style={{ borderRadius: 10 }}
      disabled
      // disabled={
      //   gettingFootprint ||
      //   sector === "food" ||
      //   givenAnswers === totalAnswers ||
      //   !formsList.some((item) => item.sector === sector)
      // }
      // onClick={() => {
      //   navigate("/performance/footprint/form", { state: { sector: sector } })
      // }}
    >
      <Stack
        style={{
          width: width,
          minWidth: width,
          height: "100%",
          borderRadius: 10,
          backgroundColor: colors.backgroundWhite,
          paddingTop: 12,
          paddingBottom: 12,
          paddingInline: 8,
          position: "relative",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{ height: 18, gap: 11 }}
        >
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: "100%",
              backgroundColor: mainCategory.backTagColor,
            }}
          />
          <Text fontSize={16} lineHeight="18px" fontWeight={500}>
            {mainCategory.name}
          </Text>
        </Stack>
        {/* progression */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            width: "100%",
            position: "absolute",
            bottom: 12,
            left: 0,
            paddingInline: 8,
          }}
        >
          <Text fontSize={12} fontWeight={400}>
            {givenAnswers}/{totalAnswers} {t("answers")}
          </Text>
          {/* {sector !== "food" &&
          givenAnswers < totalAnswers &&
          formsList.some((item) => item.sector === sector) ? (
            <Text
              fontSize={11}
              fontWeight={500}
              style={{
                textDecoration: "underline",
              }}
            >
              {t("complete")}
            </Text>
          ) : null} */}
        </Stack>
      </Stack>
    </ButtonBase>
  )
}

export default FootprintAnswersCardMobile
