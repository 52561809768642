import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const LeaderboardListItem = ({
  place,
  name,
  points,
  isUserGroup,
  height = "100%",
}: {
  place: number
  name: string
  points: number
  isUserGroup?: boolean
  height?: string | number
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: height,
        paddingInline: isMobile ? 18 : 22,
        position: "relative",
        backgroundColor: isUserGroup
          ? colors.background
          : colors.backgroundWhite,
      }}
      aria-label={t("leaderboard_place", { place: place })}
    >
      {/* place */}
      <div
        style={{
          width: "auto",
          minWidth: 26,
          height: isMobile ? 22 : 26,
          paddingInline: 7,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.primary,
          borderRadius: isMobile ? 4 : 5,
        }}
      >
        <Title fontSize={12} color={colors.textWhite} ariaHidden>
          {place}°
        </Title>
      </div>
      {/* image */}
      <div
        style={{
          width: isMobile ? 32 : 40,
          minWidth: isMobile ? 32 : 40,
          height: isMobile ? 32 : 40,
          borderRadius: "100%",
          backgroundImage: `url(https://cdn.aworld.io/users/default/profile.jpg)`,
          backgroundSize: "105%, 100%",
          backgroundPosition: "center",
          marginLeft: isMobile ? 17 : 20,
        }}
      />
      {/* name */}
      <Title
        fontSize={isMobile ? 14 : 18}
        color={isUserGroup ? colors.primary : colors.text}
        style={{
          marginLeft: isMobile ? 15 : 18,
          maxWidth: isMobile ? "calc(100% - 180px)" : "calc(100% - 200px)",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          overflow: "hidden",
          wordWrap: "break-word",
        }}
      >
        {isUserGroup ? t("your_group") : name}
      </Title>
      {/* points */}
      <Title
        fontSize={isMobile ? 14 : 18}
        color={isUserGroup ? colors.primary : colors.text}
        style={{
          position: "absolute",
          right: isMobile ? 18 : 22,
        }}
      >
        {points} {t("points")}
      </Title>
    </Stack>
  )
}

export default LeaderboardListItem
