import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import Title from "../global/common/Title"
import measureMissionIconWhite from "../../assets/icons/missions/missionsTypes/measure-white.svg"
import { Widget } from "@typeform/embed-react"
import { useContext, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import AppearFromSide from "../animations/AppearFromSide"
import { MissionPeriod, MissionType } from "../../services/config/enum"
import PointsBadge from "../global/common/PointsBadge"
import MissionCompletedFeedback from "../feedbacks/common/MissionCompletedFeedback"

const FootprintFormMobile = ({
  currentFormId,
  onTypeformSubmit,
  missionFeedbackOpen,
}: {
  currentFormId: string
  onTypeformSubmit: () => void
  missionFeedbackOpen: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight, user, currentMission } = useContext(MainContext)

  // typeform
  const typeform = useMemo(() => {
    return (
      <div
        style={{
          width: "calc(100% - 32px)",
          height: windowHeight - 190,
          position: "relative",
        }}
      >
        <Widget
          id={currentFormId}
          style={{
            width: "100%",
            height: "100%",
          }}
          onSubmit={onTypeformSubmit}
          hidden={{
            sub: user!.sub,
          }}
          inlineOnMobile
        />
        <div
          style={{
            width: "100%",
            height: 5,
            backgroundColor: colors.backgroundWhite,
            position: "absolute",
            top: 0,
          }}
        />
      </div>
    )
  }, [currentFormId])

  return !currentMission ? null : (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title fontSize={20} lineHeight="23px" style={{ height: 23 }}>
          {t("measure_mission")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* mission */}
      <AppearFromSide
        fade
        style={{ width: "100%", marginTop: 14, paddingInline: 16 }}
      >
        <Stack direction="row" gap={0.75}>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "auto",
              height: 32,
              borderRadius: 4,
              paddingInline: 8,
              backgroundColor: colors.missions.learn,
            }}
          >
            <img src={measureMissionIconWhite} style={{ width: 19 }} alt="" />
            <Title
              fontSize={14}
              color={colors.textWhite}
              style={{
                fontVariant: "all-small-caps",
                marginLeft: 8,
                marginBottom: 2,
              }}
            >
              {t("learn")}
            </Title>
            <Title
              fontSize={14}
              color={colors.textWhite}
              style={{
                fontVariant: "all-small-caps",
                marginLeft: 14,
                marginBottom: 2,
              }}
            >
              {currentMission.current}/{currentMission.target}
            </Title>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "auto",
              height: 32,
              borderRadius: 4,
              paddingInline: 8,
              border: "1px solid " + colors.tertiary,
              backgroundColor: colors.backgroundWhite,
            }}
          >
            <Title fontSize={14} color={colors.tertiary}>
              {currentMission.period === MissionPeriod.DAILY
                ? t("today")
                : currentMission.period === MissionPeriod.WEEKLY
                ? t("week")
                : t("special_singular")}
            </Title>
          </Stack>
          <PointsBadge points={currentMission.points} />
        </Stack>
      </AppearFromSide>
      <AppearFromSide
        fade
        delay={0.05}
        style={{
          width: "100%",
          height: 47,
          marginTop: 20,
          paddingInline: 16,
          position: "relative",
        }}
      >
        <Title fontSize={20} lineHeight="23.5px">
          {currentMission.title}
        </Title>
      </AppearFromSide>
      {/* typeform */}
      {typeform}
      <MissionCompletedFeedback
        open={missionFeedbackOpen}
        missionType={MissionType.MEASURE}
      />
    </Stack>
  )
}

export default FootprintFormMobile
