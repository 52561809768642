import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import OnboardingMobile from "../../components/onboarding/OnboardingMobile"
import OnboardingDesktop from "../../components/onboarding/OnboardingDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Onboarding = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("onboarding")
  }, [])

  return isMobile ? <OnboardingMobile /> : <OnboardingDesktop />
}

export default Onboarding
