import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import MenuButtonMobile from "../landing/mobile/MenuButtonMobile"
import AppearFromSide from "../animations/AppearFromSide"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"

const SettingsMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setViewTutorial } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("settings")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* menu items */}
      <Stack
        style={{
          width: "calc(100% - 32px)",
          position: "relative",
          zIndex: 1,
        }}
      >
        <AppearFromSide fade>
          <MenuButtonMobile
            title={t("edit_avatar")}
            onClick={() => {
              navigate("/performance/settings/avataredit")
            }}
          />
        </AppearFromSide>
        <AppearFromSide fade delay={0.05}>
          <MenuButtonMobile
            title={t("privacy")}
            onClick={() => {
              navigate("/performance/settings/privacy")
            }}
          />
        </AppearFromSide>
        <AppearFromSide fade delay={0.1}>
          <MenuButtonMobile
            title={t("tutorial")}
            withBorder={false}
            onClick={() => {
              navigate("/")
              setViewTutorial(true)
            }}
          />
        </AppearFromSide>
      </Stack>
    </Stack>
  )
}

export default SettingsMobile
