export const termsAndConditionsIT = `<div class="wp-block-group__inner-container">
  <p style="font-weight: 700; font-size: 18px;">TERMINI E CONDIZIONI</p>
  <p class="c2">
    <span class="c4"
      >I termini e le condizioni stabiliti nel presente documento </span
    ><span class="c0"
      >(“Termini e Condizioni”) regolano la app AWorld e le relative app..
      AWorld può limitare, sospendere, modificare, estendere o altrimenti
      modificare a sua esclusiva discrezione i termini del programma in
      qualsiasi momento e senza preavviso. Qualora i termini del programma
      vengano aggiornati, AWorld pubblicherà nuovi termini del programma e
      osserverà la data in cui sono stati modificati. Invitiamo l’utente a
      visionare i termini del programma periodicamente.</span
    >
  </p>
  <p class="c2">
    <span class="c0"
      >Utilizzando l’app AWorld l’utente accetta e acconsente di essere
      vincolato da questi Termini e Condizioni.
    </span>
  </p>
  <ol class="c8 lst-kix_k5xuazjpqh2j-0 start" start="1">
    <li class="c1 li-bullet-0">
      <span class="c4"
        >Registrazione. Per registrarsi all’app AWold non è necessario alcun
        acquisto. Per utilizzare l’app AWorld, l’utente deve completare la
        registrazione fornendo informazioni complete ed accurate e indicando
        l’accettazione di questi termini del programma e la nostra </span
      ><span class="c5">Policy sulla Privacy</span><span class="c0">. </span>
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >AWorld invierà all’utente qualsiasi comunicazione direttamente tramite
        l’app AWorld. L’utente è responsabile di mantenere l’account AWorld e di
        controllarlo regolarmente per &nbsp;la presenza di notifiche.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Il punteggio viene azzerato alla chiusura di un account AWorld.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0">Il punteggio non è trasferibile.</span>
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >I punti AW possono essere guadagnati dall’utente in relazione alle
        attività designate all’aumento dell’Impact Score e del livello nell’app
        AWorld.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >I punti AW non hanno valore in denaro e non sono intercambiabili con
        denaro. L’accumulo di punti AW non dà all’utente nessun diritto
        acquisito. AWorld non garantisce in alcun modo la presenza di premi,
        riscatto dei punti, scontistiche o qualsiasi altro benefit. AWorld non
        si assume alcuna responsabilità per l'aggiunta o l'eliminazione di
        articoli da o per i quali è possibile raccogliere punti AW.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Salvo quanto consentito da AWorld, i Punti AW non possono essere
        assegnati, scambiati, barattati, acquistati o regalati o altrimenti
        venduti. Qualsiasi punto AW così acquisito è nullo.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Condividendo il vostro indirizzo e-mail e in quanto clienti attivi e
        membri di AWorld, possiamo comunicarvi offerte speciali, servizi,
        promozioni e informazioni sui programmi. Potete annullare la ricezione
        di tali messaggi in qualsiasi momento aggiornando il vostro account
        online, contattando il servizio clienti dell'entità applicabile o
        cliccando su annullamento dell'iscrizione in fondo a qualsiasi e-mail
        promozionale.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Registrandosi con AWorld, ogni Membro (i) certifica di essere
        maggiorenne nella provincia in cui risiede (o di essere supervisionato
        da un genitore o tutore legale che accetta i Termini del Programma),
        (ii) acconsente alla raccolta e all'uso da parte dell'azienda di alcune
        informazioni personali e termini come ulteriormente descritto nella
        Politica sulla privacy, (iii) accetta i Termini del Programma, come
        possono essere modificati di volta in volta.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >Qualsiasi abuso da parte di un Membro, qualsiasi inadempienza da parte
        di un Membro nel rispettare i presenti Termini e condizioni, o qualsiasi
        falsa dichiarazione da parte di un Membro può comportare l'espulsione
        del Membro dalla piattaforma, compresa la perdita di tutti i punti
        accumulati.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >COME CONSENTITO DALLA LEGGE APPLICABILE, ACCETTATE ESPRESSAMENTE CHE
        L'USO DELL'APP È A VOSTRO ESCLUSIVO RISCHIO. NÉ AWORLD, NÉ LE SUE
        CONSOCIATE O AFFILIATE, NÉ I RISPETTIVI DIPENDENTI, AGENTI, PARTNER,
        FORNITORI DI CONTENUTI DI TERZE PARTI O LICENZIATARI, NÉ I LORO
        FUNZIONARI, DIRETTORI, DIPENDENTI O AGENTI, GARANTISCONO CHE L'USO
        DELL'APP SARÀ ININTERROTTO, SICURO, PRIVO DI VIRUS O DI ERRORI, NÉ
        FORNISCONO ALCUNA GARANZIA IN MERITO A (A) I RISULTATI CHE POSSONO
        ESSERE OTTENUTI DALL'USO DELL'APP, O (B) L'ACCURATEZZA, LA COMPLETEZZA O
        L'AFFIDABILITÀ (I) DEL CONTENUTO DELL'APP, INCLUSI, A TITOLO
        ESEMPLIFICATIVO, LE OFFERTE DEI PARTNER, I PRODOTTI O ALTRI PROGRAMMI
        DISPONIBILI, (II) LE DESCRIZIONI DELLE OFFERTE DEI PARTNER, DEI PRODOTTI
        O DI ALTRI PROGRAMMI DISPONIBILI, O (III) IL CONTENUTO DELL'UTENTE
        FORNITO ATTRAVERSO L'APP. L'APP E TUTTI I CONTENUTI, I CONTENUTI
        DELL’UTENTE E LE ALTRE INFORMAZIONI CONTENUTE NELL'APP, LE OFFERTE DEI
        PARTNER, I PRODOTTI E GLI ALTRI PROGRAMMI DISPONIBILI ACCESSIBILI O
        DISPONIBILI ATTRAVERSO L'APP, SONO RESI ACCESSIBILI O DISPONIBILI "COSÌ
        COME SONO" E "COME DISPONIBILI". NELLA MISURA CONSENTITA DALLA LEGGE
        APPLICABILE, CON IL PRESENTE DOCUMENTO AWORLD DECLINA QUALSIASI
        DICHIARAZIONE, GARANZIA E CONDIZIONE, SIA ESPRESSA, IMPLICITA O DI
        LEGGE, IN MERITO AL FUNZIONAMENTO DELL'APP O AL CONTENUTO, AL CONTENUTO
        DELL'UTENTE O AD ALTRE INFORMAZIONI CONTENUTE NELL'APP O ALLE OFFERTE
        DEI PARTNER, AI PRODOTTI O AD ALTRI PROGRAMMI DISPONIBILI ACCESSIBILI O
        DISPONIBILI ATTRAVERSO L'APP, COMPRESE, SENZA LIMITAZIONE, QUELLE DI
        TITOLO, NON VIOLAZIONE, NON INTERFERENZA, COMMERCIABILITÀ, IDONEITÀ E
        IDONEITÀ PER UNO SCOPO PARTICOLARE, COSÌ COME LE GARANZIE IMPLICITE IN
        UN CORSO DI PRESTAZIONI O IN UN CORSO DI AFFARI. LE LIMITAZIONI DI
        GARANZIA IN QUESTA SEZIONE NON INTENDONO LIMITARE ALCUNA GARANZIA
        FORNITA DIRETTAMENTE DA UN PARTNER O DAL PRODUTTORE APPLICABILE DI
        PRODOTTI FISICI O QUALSIASI DICHIARAZIONE O GARANZIA ESPRESSA DA PARTE
        DI AWORLD CHE SIA INCLUSA IN ALTRI TERMINI APPLICABILI.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >L'APP può contenere link a siti web gestiti da altre parti. Questi link
        sono forniti esclusivamente per comodità dell'utente e non perché
        approviamo o abbiamo un'opinione sui contenuti di tali siti web.
        Decliniamo espressamente qualsiasi dichiarazione in merito al contenuto
        o all'accuratezza dei materiali presenti su tali siti web o alle
        pratiche sulla privacy di tali siti web. Se decidete di accedere a siti
        web gestiti da altre parti, lo fate a vostro rischio e pericolo. Non
        siamo responsabili, direttamente o indirettamente, per qualsiasi danno,
        perdita o responsabilità causata o presumibilmente causata da o in
        connessione con l'uso o l'affidamento su qualsiasi contenuto, prodotto o
        servizio disponibile su o attraverso qualsiasi sito o risorsa
        collegata.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >L'utente accetta di difendere, indennizzare e manlevare AWORLD, le sue
        consociate e affiliate e i rispettivi direttori, funzionari, dipendenti
        e agenti da e contro tutte le richieste di risarcimento e le spese,
        incluse, senza limitazioni, le spese legali, derivanti da, relative a, o
        in relazione a, quanto segue: (a) qualsiasi contenuto inviato o
        pubblicato dall'utente, in relazione all'APP, o qualsiasi utilizzo
        dell'APP in violazione delle presenti Condizioni d'uso; (b) frode
        commessa dall'utente o sua cattiva condotta intenzionale o grave
        negligenza; o (c) violazione da parte dell'utente di qualsiasi legge
        statunitense, canadese o straniera applicabile o dei diritti di
        terzi.</span
      >
    </li>
    <li class="c1 li-bullet-0">
      <span class="c0"
        >L'utente è l'unico responsabile delle proprie interazioni con i partner
        e gli altri utenti dell'APP. Nella misura consentita dalle leggi
        applicabili, l'utente solleva AWORLD da qualsiasi rivendicazione o
        responsabilità relativa a qualsiasi prodotto o servizio di un partner,
        indipendentemente dal fatto che tale prodotto o servizio sia un'offerta
        di un partner disponibile attraverso l'APP, qualsiasi azione o inazione
        da parte di un partner, compresi, a titolo esemplificativo, ma non solo,
        qualsiasi danno causato all'utente dall'azione o dall'inazione di un
        partner, il mancato rispetto da parte di un partner della legge
        applicabile e/o il mancato rispetto dei termini di un'offerta del
        partner o di qualsiasi altro prodotto o servizio acquistato o ottenuto
        dall'utente dal partner, e qualsiasi condotta, discorso o contenuto
        dell’utente, sia online che offline, di qualsiasi altra terza
        parte.</span
      >
    </li>
  </ol>
</div>`
