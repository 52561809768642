import { useContext } from "react"
import { TagsContext } from "../../../controllers/tags"
import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { FootprintContext } from "../../../controllers/footprint"
import { PulseLoader } from "react-spinners"
import MainCategory from "../../../models/mainCategory"

const FootprintCategoryCardMobile = ({
  mainCategory,
  withBorder = false,
  value,
}: {
  mainCategory: MainCategory
  withBorder?: boolean
  value?: number
}) => {
  const { categories } = useContext(TagsContext)
  const { gettingFootprint } = useContext(FootprintContext)

  return (
    <tr
      style={{
        width: "100%",
        position: "relative",
        borderTop: withBorder ? "1px solid " + colors.stroke : "none",
      }}
    >
      <td style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Stack style={{ gap: 16 }}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ gap: 8, position: "relative" }}
          >
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: "100%",
                backgroundColor: mainCategory.backTagColor,
              }}
            />
            <Title fontSize={16}>{mainCategory.name}</Title>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            style={{ height: 24, gap: 8 }}
          >
            {categories
              .filter(
                (category) =>
                  category.mainCategory &&
                  category.mainCategory.id === mainCategory.id
              )
              .map((category) => (
                <div
                  key={category.id}
                  style={{
                    width: "auto",
                    height: "100%",
                    paddingInline: 8,
                    backgroundColor: category.backTagColor,
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Title fontSize={12} color={category.foreColor}>
                    {category.name}
                  </Title>
                </div>
              ))}
          </Stack>
        </Stack>
      </td>
      <td
        style={{
          display: "flex",
          justifyContent: "right",
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        {gettingFootprint ? (
          <span style={{ height: 22 }}>
            <PulseLoader color={colors.text} size={5} />
          </span>
        ) : (
          <Title fontSize={16} fontWeight={500}>
            {value ? value.toString().replace(".", ",") : "--"}
          </Title>
        )}
      </td>
    </tr>
  )
}

export default FootprintCategoryCardMobile
