import { colors } from "../../services/config/colors"
import { ButtonBase, Stack } from "@mui/material"
import menuIcon from "../../assets/icons/menu.svg"
import dotsFirstImage from "../../assets/images/dots-1.svg"
import dotsSecondImage from "../../assets/images/dots-2.svg"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import AppearFromSide from "../animations/AppearFromSide"
import Appear from "../animations/Appear"
import MissionsCarouselMobile from "./mobile/MissionsCarouselMobile"
import partnerPoweredByAWorldLogo from "../../assets/images/partner-powered-by-aworld.png"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import ChallengeBottomSheetMobile from "./mobile/ChallengeBottomSheetMobile"
import { motion, useReducedMotion } from "framer-motion"
import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MissionsContext } from "../../controllers/missions"
import MenuMobile from "./mobile/MenuMobile"
import { MainContext } from "../../controllers/main"
import AlertInfo from "../global/common/AlertInfo"
import CountUp from "react-countup"

const LandingMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const shouldReduceMotion = useReducedMotion()
  const { user, viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { missions } = useContext(MissionsContext)

  // side menus status
  const [performanceOpen, setPerformanceOpen] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  // bottom sheet open (always open, but closes when a mission card is clicked)
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(true)

  // what is a mission alert
  const [missionInfoAlertOpen, setMissionInfoAlertOpen] =
    useState<boolean>(false)

  // come back tomorrow alert
  const [comeBackAlertOpen, setComeBackAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      {/* top bar */}
      <AppearFromSide
        x={-108}
        style={{
          position: "absolute",
          top: 22,
          left: -20,
        }}
      >
        <ButtonBase
          style={{
            width: 138,
            height: 40,
            backgroundColor: colors.backgroundWhite,
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingLeft: 38,
            boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.16)",
          }}
          onClick={() => {
            setMenuOpen(true)
          }}
          aria-label={t("open_menu")}
        >
          <Text fontSize={15} fontWeight={700} color={colors.primary}>
            {t("menu")}
          </Text>
          <img
            src={menuIcon}
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              right: 7,
            }}
            alt=""
          />
        </ButtonBase>
      </AppearFromSide>
      <FadeFromTop style={{ position: "absolute", top: 30 }}>
        <img
          src={partnerPoweredByAWorldLogo}
          style={{ height: 30 }}
          alt={t("partner_logo_alt")}
        />
      </FadeFromTop>
      <AppearFromSide
        id="performance-button"
        x={108}
        style={{ position: "absolute", top: 22, right: -20 }}
      >
        <ButtonBase
          style={{
            width: 138,
            height: 40,
            backgroundColor: colors.backgroundWhite,
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: 38,
            boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.16)",
          }}
          onClick={() => {
            if (shouldReduceMotion) {
              navigate("/performance")
            } else {
              setPerformanceOpen(true)
              setTimeout(() => {
                navigate("/performance")
              }, 180)
            }
          }}
          aria-label={t("performance")}
        >
          {viewTutorial &&
          document.getElementById("performance-button")?.style.zIndex ===
            "150" ? (
            <Stack direction="row" gap={0.5}>
              <CountUp
                start={user?.points}
                end={286}
                duration={12}
                style={{
                  fontSize: `${16 * 0.063}rem`,
                  fontWeight: 700,
                  color: colors.primary,
                }}
              />
              <Text fontSize={16} fontWeight={700} color={colors.primary}>
                {t("points")}
              </Text>
            </Stack>
          ) : (
            <Text fontSize={16} fontWeight={700} color={colors.primary}>
              {user?.points} {t("points")}
            </Text>
          )}
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: "100%",
              position: "absolute",
              left: 7,
              backgroundImage: `url(${
                user!.profileImage
              }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
              backgroundSize: "105%, 100%",
              backgroundPosition: "center",
            }}
          />
        </ButtonBase>
      </AppearFromSide>
      {/* performance */}
      <motion.div
        animate={performanceOpen ? "open" : "closed"}
        variants={{
          open: { x: 0 },
          closed: { x: "100%" },
        }}
        transition={{ x: { type: "just", duration: 0.2 } }}
        initial={
          location.state &&
          location.state.fromPerformance &&
          !shouldReduceMotion
            ? true
            : false
        }
        style={{
          width: "100%",
          height: "100%",
          minHeight: "100%",
          backgroundColor: colors.backgroundWhite,
          position: "absolute",
          zIndex: 10,
        }}
      />
      {/* menu */}
      <MenuMobile open={menuOpen} setOpen={setMenuOpen} />
      {/* decorations */}
      <Appear
        style={{ position: "absolute", top: 98 }}
        delay={0.3}
        fade
        fadeDuration={0.2}
      >
        <div
          style={{
            width: 230,
            height: 69,
          }}
        >
          <img
            src={dotsFirstImage}
            style={{
              width: 17,
              height: 12,
              position: "absolute",
              top: 0,
              left: 0,
            }}
            alt=""
          />
          <img
            src={dotsSecondImage}
            style={{
              width: 17,
              height: 18,
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
            alt=""
          />
        </div>
      </Appear>
      {/* heading */}
      <FadeFromTop
        style={{
          marginTop:
            viewTutorial &&
            currentTutorialPage > 1 &&
            document.getElementById("carousel-container-mobile") &&
            document.getElementById("carousel-container-mobile")!.style
              .zIndex === "150"
              ? -65
              : 95,
          transition: "margin-top 200ms ease-out",
        }}
      >
        <Stack alignItems="center">
          <Title
            fontSize={30}
            lineHeight="34px"
            textAlign="center"
            style={{
              width: 180,
              opacity:
                viewTutorial &&
                currentTutorialPage > 1 &&
                document.getElementById("carousel-container-mobile") &&
                document.getElementById("carousel-container-mobile")!.style
                  .zIndex === "150"
                  ? 0
                  : 1,
              transition: "150ms",
            }}
            component="h1"
          >
            {t("your_missions")}
          </Title>
          <ButtonBase
            disableRipple
            style={{
              marginTop: 25,
              opacity:
                viewTutorial &&
                currentTutorialPage > 1 &&
                document.getElementById("carousel-container-mobile") &&
                document.getElementById("carousel-container-mobile")!.style
                  .zIndex === "150"
                  ? 0
                  : 1,
              transition: "150ms",
            }}
            onClick={() => {
              setMissionInfoAlertOpen(true)
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                height: 16,
                gap: 4,
              }}
            >
              <img
                src={infoIconPrimary}
                style={{ width: 16, height: 16 }}
                alt=""
              />
              <Text
                fontSize={15}
                fontWeight={700}
                color={colors.primary}
                lineHeight="16px"
              >
                {t("what_is_a_mission")}
              </Text>
            </Stack>
          </ButtonBase>
        </Stack>
      </FadeFromTop>
      {/* carousel */}
      <Appear style={{ marginTop: 34, width: "100%" }} fade>
        <MissionsCarouselMobile
          setBottomSheetOpen={setBottomSheetOpen}
          setPerformanceOpen={setPerformanceOpen}
          setComeBackAlertOpen={setComeBackAlertOpen}
        />
      </Appear>
      {/* footer */}
      {missions.length ? (
        <FadeFromBottom style={{ marginTop: 34, marginBottom: 120 }}>
          <Text
            fontSize={15}
            fontWeight={700}
            style={{ textDecorationLine: "underline" }}
            color={colors.primary}
          >
            {missions.filter((item) => item.satisfied).length}{" "}
            {missions.filter((item) => item.satisfied).length === 1
              ? t("completed_singular")
              : t("completed_plural")}
          </Text>
        </FadeFromBottom>
      ) : null}
      {/* draggable bottom sheet */}
      <ChallengeBottomSheetMobile open={bottomSheetOpen} />
      {/* what is a mission info alert */}
      <AlertInfo
        open={missionInfoAlertOpen}
        setOpen={setMissionInfoAlertOpen}
        title={t("what_is_a_mission")}
        description={t("what_is_a_mission_description")}
        descriptionAlign="center"
      />
      {/* come back tomorrow info alert */}
      <AlertInfo
        open={comeBackAlertOpen}
        setOpen={setComeBackAlertOpen}
        title={t("thats_all_for_today")}
        description={t("come_back_tomorrow_to_continue")}
        descriptionAlign="center"
      />
      {/* tutorial backdrop */}
      {viewTutorial && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "absolute",
            top: 0,
            zIndex: 100,
          }}
        />
      )}
    </Stack>
  )
}

export default LandingMobile
