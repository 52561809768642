export const privacyPolicyIT = `
<p class="c15 c24">
  <span class="c6 c1"
    >Informativa e richiesta di consenso ai sensi e per gli effetti degli artt.
    13 e 14 del Regolamento (UE) 2016/679, relativo alla tutela del trattamento
    dei dati personali</span
  >
</p>
<p class="c8 c11"><span class="c5"></span></p>
<p class="c8 c11"><span class="c5"></span></p>
<p class="c21"><span class="c1">Titolare del Trattamento dei Dati</span></p>
<p class="c25">
  <span class="c16">Titolare del trattamento è </span
  ><span class="c1 c17">AWorld Srl Società Benefit, </span
  ><span class="c16 c17">con sede legale in</span
  ><span class="c1 c17">&nbsp;</span
  ><span class="c16 c17"
    >Via Agostino da Montefeltro n. 2, 10134 – Torino, Italia, P.IVA
    11867110014</span
  ><span class="c5">, in persona del suo legale rappresentante.</span>
</p>
<p class="c25">
  <span class="c16">Contatti: </span
  ><span class="c16 c28">privacy@aworld.it</span>
</p>
<p class="c25">
  <span class="c6 c1">Responsabile della Protezione dei Dati</span>
</p>
<p class="c25">
  <span class="c16">Responsabile della protezione dei dati è la </span
  ><span class="c6 c1">Dott.ssa Debora Marianna Pagano</span>
</p>
<p class="c25">
  <span class="c16">Contatti: </span
  ><span class="c28 c16">privacy@emade.pro</span><span class="c5">&nbsp;</span>
</p>
<p class="c3"><span class="c6 c1">Finalità del trattamento</span></p>
<p class="c27">
  <span class="c5"
    >I dati personali forniti dagli utenti verranno utilizzati per:</span
  >
</p>
<ul class="c20 lst-kix_list_1-0 start">
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Presentare o migliorare l’App e i servizi inclusa l’analisi delle
      attività dell’App;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Informarti dei servizi e prodotti disponibili di AWorld;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Autorizzare l’accesso all’App e ai servizi;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Fornire, mantenere o espandere i servizi, analisi sulle performance del
      business, e altri prodotti e servizi che offriamo;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Offrire e amministrare programmi;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Personalizzare la tua esperienza;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Registrare la partecipazione dell’utente ad eventi tramite GPS;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Rendere visibile agli altri partecipanti la partecipazione dell’utente ad
      un determinato evento;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Creare contenuti, promozioni, concorsi, sondaggi e altre funzionalità
      dell’App;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Gestire la tua partecipazione in programmi speciali, sondaggi, campagne,
      concorsi e altre offerte o promozioni; e per inviarti e-mail a
      riguardo;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >La sicurezza dei nostri servizi, inclusa l’autenticazione degli
      utenti;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Utilizzare i dati statistici che raccogliamo nelle modalità previste per
      legge;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Supportare gli utenti nell’utilizzo dell’App;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Rispettare i requisiti di legge applicabili;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Rispettare le Condizioni d’uso e altri accordi;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">Eseguire analisi sui dati;</span>
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5"
      >Indagare su possibili frodi o altre violazioni delle Condizioni d’uso o
      di questa Informativa;</span
    >
  </li>
  <li class="c12 li-bullet-0">
    <span class="c5">La risoluzione di controversie;</span>
  </li>
  <li class="c29 c48 li-bullet-0">
    <span class="c5"
      >Adempiere in altro modo allo scopo per le quali le informazioni sono
      state raccolte.</span
    >
  </li>
</ul>
<p class="c29">
  <span class="c5"
    >Chiederemo il tuo consenso per raccogliere i tuoi dati personali per fini
    diversi da quelli sopra elencati.</span
  >
</p>
<p class="c3"><span class="c6 c1">Categorie di dati trattati</span></p>
<p class="c3"><span class="c5">I dati personali trattati sono:</span></p>
<p class="c3"><span class="c5">A - Dati di contatto</span></p>
<p class="c3">
  <span class="c5">B -Immagine del profilo e foto degli eventi</span>
</p>
<p class="c3"><span class="c5">C -Localizzazione</span></p>
<p class="c3"><span class="c5">D - Abitudini di vita e di consumo</span></p>
<p class="c3"><span class="c6 c1">Modalità del trattamento</span></p>
<p class="c15">
  <span class="c5"
    >I dati personali degli utenti sono trattati con le opportune misure di
    sicurezza; tali misure sono utili a impedire la divulgazione, la modifica o
    altri utilizzi non autorizzati. Oltre al Titolare, potrebbero avere accesso
    ai Dati Personali persone autorizzate dal Titolare.</span
  >
</p>
<p class="c49"><span class="c6 c1">Conseguenze del rifiuto dei dati</span></p>
<p class="c27">
  <span class="c5"
    >In caso di mancata autorizzazione al trattamento dei dati previsti nei
    punti A e D l'interessato non potrà utilizzare l’App.</span
  >
</p>
<p class="c27">
  <span class="c5"
    >In caso di mancata autorizzazione al trattamento dei dati previsti nei
    punti B e C l'interessato non potrà utilizzare servizi specifici.</span
  >
</p>
<p class="c3"><span class="c1 c6">Periodo di conservazione</span></p>
<p class="c14">
  <span class="c5"
    >I Dati sono trattati e conservati per il tempo richiesto dalle finalità per
    le quali sono stati raccolti. Pertanto:</span
  >
</p>
<ul class="c20 lst-kix_list_4-0 start">
  <li class="c31 li-bullet-0">
    <span class="c5"
      >I Dati Personali raccolti per scopi collegati all’esecuzione di un
      contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia
      completata l’esecuzione di tale contratto.</span
    >
  </li>
  <li class="c39 li-bullet-0">
    <span class="c5"
      >I Dati Personali raccolti per finalità riconducibili all’interesse
      legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale
      interesse. L’Utente può ottenere ulteriori informazioni in merito
      all’interesse legittimo perseguito dal Titolare nelle relative sezioni di
      questo documento o contattando il Titolare.</span
    >
  </li>
  <li class="c44 li-bullet-0">
    <span class="c5"
      >I dati personali raccolti con finalità di marketing promozionali saranno
      conservati per un periodo di 24 mesi.</span
    >
  </li>
  <li class="c39 li-bullet-0">
    <span class="c5"
      >Il Titolare potrebbe essere obbligato a conservare i Dati Personali per
      un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine
      di un’autorità.</span
    >
  </li>
  <li class="c39 li-bullet-0">
    <span class="c5"
      >Al termine del periodo di conservazioni i Dati Personali saranno
      cancellati. Pertanto, allo spirare di tale termine il diritto di accesso,
      cancellazione, rettificazione ed il diritto alla portabilità dei Dati non
      potranno più essere esercitati.</span
    >
  </li>
</ul>
<p class="c11 c41"><span class="c5"></span></p>
<p class="c26"><span class="c6 c1">Diritti dell'interessato</span></p>
<p class="c30">
  <span class="c5"
    >Gli Utenti possono esercitare determinati diritti con riferimento ai Dati
    trattati dal Titolare.</span
  >
</p>
<p class="c40">
  <span class="c5">In particolare, l’Utente ha il diritto di:</span>
</p>
<p class="c11 c46"><span class="c5"></span></p>
<ul class="c20 lst-kix_list_3-0 start">
  <li class="c47 li-bullet-0">
    <span class="c5"
      >revocare il consenso in ogni momento. L’Utente può revocare il consenso
      al trattamento dei propri Dati Personali precedentemente espresso.</span
    >
  </li>
  <li class="c9 li-bullet-0">
    <span class="c5"
      >opporsi al trattamento dei propri Dati. L’Utente può opporsi al
      trattamento dei propri Dati quando esso avviene su una base giuridica
      diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono
      indicati nella sezione sottostante.</span
    >
  </li>
  <li class="c4 li-bullet-0">
    <span class="c5"
      >accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui
      Dati trattati dal Titolare, su determinati aspetti del trattamento ed a
      ricevere una copia dei Dati trattati.</span
    >
  </li>
  <li class="c23 li-bullet-0">
    <span class="c5"
      >verificare e chiedere la rettificazione. L’Utente può verificare la
      correttezza dei propri Dati e richiederne l’aggiornamento o la
      correzione.</span
    >
  </li>
  <li class="c9 li-bullet-0">
    <span class="c5"
      >ottenere la limitazione del trattamento. Quando ricorrono determinate
      condizioni, l’Utente può richiedere la limitazione del trattamento dei
      propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro
      scopo se non la loro conservazione.</span
    >
  </li>
  <li class="c23 li-bullet-0">
    <span class="c5"
      >ottenere la cancellazione o rimozione dei propri Dati Personali. Quando
      ricorrono determinate condizioni, l’Utente può richiedere la cancellazione
      dei propri Dati da parte del Titolare.</span
    >
  </li>
  <li class="c9 li-bullet-0">
    <span class="c5"
      >ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha
      diritto di ricevere i propri Dati in formato strutturato, di uso comune e
      leggibile da dispositivo automatico e, ove tecnicamente fattibile, di
      ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa
      disposizione è applicabile quando i Dati sono trattati con strumenti
      automatizzati ed il trattamento è basato sul consenso dell’Utente, su un
      contratto di cui l’Utente è parte o su misure contrattuali ad esso
      connesse.</span
    >
  </li>
  <li class="c9 li-bullet-0">
    <span class="c5"
      >proporre reclamo. L’Utente può proporre un reclamo all’autorità di
      controllo della protezione dei dati personali competente o agire in sede
      giudiziale. Per farlo puoi utilizzare un modello messo a disposizione dal
      Garante Privacy.</span
    >
  </li>
</ul>
<p class="c8 c11"><span class="c5"></span></p>
<p class="c38">
  <span class="c5"
    >Ai sensi del medesimo articolo si ha il diritto di chiedere la
    cancellazione, la trasformazione informa anonima o il blocco dei dati
    trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi
    legittimi, al loro trattamento.</span
  >
</p>
<p class="c36">
  <span class="c5"
    >L'interessato ha diritto di opporsi, in tutto o in parte: per motivi
    legittimi al trattamento dei dati personali che lo riguardano, ancorché
    pertinenti allo scopo della raccolta; al trattamento di dati personali che
    lo riguardano a fini di invio di materiale pubblicitario o di vendita
    diretta o per il compimento di ricerche di mercato.</span
  >
</p>
<p class="c22"><span class="c6 c1">Come esercitare i diritti</span></p>
<p class="c33">
  <span class="c5"
    >Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una
    richiesta agli estremi di contatto del Titolare indicati in questo
    documento. Le richieste sono depositate a titolo gratuito ed evase dal
    Titolare nel più breve tempo possibile, in ogni caso entro un mese.</span
  >
</p>
<p class="c32"><span class="c6 c1">Modifiche a questa privacy policy</span></p>
<p class="c27">
  <span class="c5"
    >Il Titolare del Trattamento si riserva il diritto di apportare modifiche
    alla presente privacy policy in qualunque momento dandone informazione agli
    Utenti su questa pagina e, se possibile qualora tecnicamente e legalmente
    fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di
    contatto di cui è in possesso il Titolare. Si prega dunque di consultare
    regolarmente questa pagina, facendo riferimento alla data di ultima modifica
    indicata in fondo.</span
  >
</p>
<p class="c11 c35"><span class="c5"></span></p>
<p class="c8"><span class="c5">Ultima modifica: 23 Febbraio 2022</span></p>
<div>
  <p class="c7"><span class="c2"></span></p>
  <a id="t.bf1ebab0f7a2f0c20e117597f4cfc6a4ff534157"></a><a id="t.1"></a>
  <table class="c34">
    <tbody>
      <tr class="c43">
        <td class="c18" colspan="1" rowspan="1">
          <p class="c8 c13 c11"><span class="c2"></span></p>
        </td>
        <td class="c18" colspan="1" rowspan="1">
          <p class="c10"><span class="c2"></span></p>
        </td>
        <td class="c18" colspan="1" rowspan="1">
          <p class="c11 c42"><span class="c2"></span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <p class="c8 c11"><span class="c2"></span></p>
</div>`
