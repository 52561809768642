import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import partnerPoweredByAWorldLogo from "../../assets/images/partner-powered-by-aworld.png"
import { useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import introImage from "../../assets/images/onboarding/intro.png"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import checkWhiteIcon from "../../assets/icons/check-white.svg"
import Alert from "../global/common/Alert"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import AlertFullScreen from "../global/common/AlertFullScreen"
import { termsAndConditionsIT } from "../../services/data/termsAndConditions"
import { privacyPolicyIT } from "../../services/data/privacyPolicy"
import { focusElement } from "../../services/utils/utils"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import backgroundImage from "../../assets/images/onboarding-background.jpg"
import backgroundImageMobile from "../../assets/images/onboarding-background-mobile.jpg"

const OnboardingMobile = ({
  height,
  continueButtonWidth,
}: {
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const { windowHeight, signUp, isMobile } = useContext(MainContext)

  // current page
  const [currentPage, setCurrentPage] = useState<number>(0)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // terms and privacy accepted
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false)

  // loading for signup
  const [loading, setLoading] = useState<boolean>(false)

  // something is missing alert
  const [missingAlertOpen, setMissingAlertOpen] = useState<boolean>(false)

  // terms and privacy alerts
  const [termsAlertOpen, setTermsAlertOpen] = useState<boolean>(false)
  const [privacyAlertOpen, setPrivacyAlertOpen] = useState<boolean>(false)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  return (
    <Stack
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundImage: isMobile ? "none" : `url(${backgroundImage})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundColor: colors.backgroundWhite,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: 800,
          minHeight: 800,
          overflow: "hidden",
          paddingTop: 26,
        }}
        tabIndex={0}
      >
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
          }}
          alignItems="center"
          aria-hidden={currentPage === 0 ? "false" : "true"}
        >
          {/* header */}
          <FadeFromTop>
            <img
              src={partnerPoweredByAWorldLogo}
              style={{ height: 42 }}
              alt={t("partner_logo_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 30 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
            >
              {t("onboarding_welcome")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
            >
              {t("onboarding_subtitle")}
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.15} style={{ marginTop: 42 }}>
            <img
              src={introImage}
              style={{ width: 203, height: 214 }}
              alt={t("onboarding_image_alt")}
            />
          </FadeFromTop>
          <FadeFromTop delay={0.2} style={{ marginTop: 42 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              style={{ maxWidth: onboardingElementsMaxWidth }}
            >
              <Trans i18nKey="onboarding_description">
                <strong style={{ fontWeight: 500 }}>
                  Agisci insieme a Enel e AWorld.
                </strong>{" "}
                Completa le missioni, contribuisci alla challenge collettiva e{" "}
                <strong style={{ fontWeight: 500 }}>
                  scopri come anche tu puoi fare la differenza
                </strong>{" "}
                per aiutare il Pianeta!
              </Trans>
            </Text>
          </FadeFromTop>
        </Stack>
        {/* second page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: 16,
            position: "relative",
          }}
          alignItems="center"
          aria-hidden={currentPage === 1 ? "false" : "true"}
        >
          {isMobile && (
            <img
              src={backgroundImageMobile}
              style={{
                width: "100%",
                maxWidth: 375,
                position: "absolute",
                top: 350,
                right: 0,
              }}
              alt=""
            />
          )}
          <img
            src={partnerPoweredByAWorldLogo}
            style={{ height: 42 }}
            alt={t("partner_logo_alt")}
          />
          <Title
            fontSize={isMobile ? 26 : 28}
            textAlign="center"
            style={{ marginTop: 30 }}
            component="h1"
          >
            {t("the_collective_challenge").charAt(0).toUpperCase() +
              t("the_collective_challenge").slice(1).toLowerCase()}
          </Title>
          <Text
            fontSize={isMobile ? 18 : 20}
            textAlign="center"
            fontWeight={400}
            style={{ marginTop: 12 }}
            component="h2"
          >
            {t("how_does_it_work")}
          </Text>
          <Stack
            style={{
              maxWidth: onboardingElementsMaxWidth,
              position: "relative",
            }}
          >
            <Text
              fontSize={isMobile ? 18 : 20}
              fontWeight={500}
              style={{ width: "100%", marginTop: 60 }}
            >
              {t("onboarding_challenge_title")}
            </Text>
            <Text fontSize={isMobile ? 18 : 20} style={{ width: "100%" }}>
              {t("onboarding_challenge_description")}
            </Text>
            <Text
              fontSize={isMobile ? 18 : 20}
              fontWeight={500}
              style={{ width: "100%", marginTop: 25 }}
            >
              {t("complete_the_missions")}
            </Text>
            <Text fontSize={isMobile ? 18 : 20} style={{ width: "100%" }}>
              {t("onboarding_leaderboard_description")}
            </Text>
            <Stack
              style={{
                width: "100%",
                height: "auto",
                minHeight: 166,
                backgroundColor: colors.backgroundWhite,
                borderRadius: 16,
                marginTop: 62,
                overflow: "hidden",
                position: "relative",
              }}
            >
              {/* terms and conditions button */}
              <ButtonBase
                disabled={loading}
                style={{ width: "100%", height: "50%" }}
                onClick={() => {
                  setTermsAccepted((current) => !current)
                }}
                tabIndex={currentPage === 1 ? 0 : -1}
                aria-hidden={currentPage === 1 ? "false" : "true"}
                aria-live="assertive"
                role="checkbox"
                aria-checked={termsAccepted ? "true" : "false"}
              >
                <Stack
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: 16,
                    position: "relative",
                  }}
                >
                  <Title fontSize={isMobile ? 18 : 20} lineHeight="25px">
                    {t("terms_and_conditions")}
                  </Title>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    lineHeight={isMobile ? "18px" : "20px"}
                    fontWeight={400}
                    style={{ width: "80%" }}
                  >
                    <Trans i18nKey="terms_and_conditions_acceptance">
                      Accetto{" "}
                      <span
                        style={{
                          color: colors.primary,
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          fontSize: isMobile ? 14 : 16,
                          paddingInline: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setTermsAlertOpen(true)
                        }}
                        role="button"
                        tabIndex={currentPage === 1 ? 0 : -1}
                        aria-hidden={currentPage === 1 ? "false" : "true"}
                      >
                        i termini e le condizioni
                      </span>{" "}
                      del servizio
                    </Trans>
                  </Text>
                  <div
                    style={{
                      width: isMobile ? 24 : 26,
                      height: isMobile ? 24 : 26,
                      position: "absolute",
                      right: 16,
                      backgroundColor: termsAccepted
                        ? colors.primary
                        : colors.backgroundWhite,
                      border: "1.5px solid " + colors.primary,
                      borderRadius: "100%",
                      transition: "150ms",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={checkWhiteIcon}
                      style={{ width: isMobile ? 12 : 14 }}
                      alt=""
                    />
                  </div>
                </Stack>
              </ButtonBase>
              {/* privacy policy button */}
              <ButtonBase
                disabled={loading}
                style={{ width: "100%", height: "50%" }}
                onClick={() => {
                  setPrivacyAccepted((current) => !current)
                }}
                tabIndex={currentPage === 1 ? 0 : -1}
                aria-hidden={currentPage === 1 ? "false" : "true"}
                role="checkbox"
                aria-checked={termsAccepted ? "true" : "false"}
              >
                <Stack
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: 16,
                    position: "relative",
                  }}
                >
                  <Title fontSize={isMobile ? 18 : 20} lineHeight="25px">
                    {t("privacy_policy")}
                  </Title>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    lineHeight={isMobile ? "18px" : "20px"}
                    fontWeight={400}
                    style={{ width: "80%" }}
                  >
                    <Trans i18nKey="privacy_policy_acceptance">
                      Ho preso visione della{" "}
                      <span
                        style={{
                          color: colors.primary,
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          fontSize: isMobile ? 14 : 16,
                          paddingInline: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setPrivacyAlertOpen(true)
                        }}
                        role="button"
                        tabIndex={currentPage === 1 ? 0 : -1}
                        aria-hidden={currentPage === 1 ? "false" : "true"}
                      >
                        politica di riservatezza
                      </span>
                    </Trans>
                  </Text>
                  <div
                    style={{
                      width: isMobile ? 24 : 26,
                      height: isMobile ? 24 : 26,
                      position: "absolute",
                      right: 16,
                      backgroundColor: privacyAccepted
                        ? colors.primary
                        : colors.backgroundWhite,
                      border: "1.5px solid " + colors.primary,
                      borderRadius: "100%",
                      transition: "150ms",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={checkWhiteIcon}
                      style={{ width: isMobile ? 12 : 14 }}
                      alt=""
                    />
                  </div>
                </Stack>
              </ButtonBase>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* continue and go back buttons */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: currentPage === 0 ? 22 : 90,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
      >
        <Button
          loading={loading}
          title={t("continue")}
          width="100%"
          onClick={() => {
            if (canClick.current) {
              if (!currentPage) {
                const container = document.getElementById(
                  "onboarding-container"
                )
                container?.scrollTo({
                  left: window.innerWidth,
                  // behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  behavior: "auto",
                })
                setCurrentPage(1)

                if (screenReaderEnabled.current) {
                  focusElement("onboarding-container")
                }

                canClick.current = false
                setTimeout(() => {
                  canClick.current = true
                }, 500)
              } else {
                if (termsAccepted && privacyAccepted) {
                  setLoading(true)
                  signUp()
                } else {
                  setMissingAlertOpen(true)
                }
              }
            }
          }}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          {t("continue")}
        </Button>
      </FadeFromBottom>
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: currentPage === 0 ? -56 : 22,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
      >
        <Button
          disabled={loading}
          title={t("go_back")}
          outlined
          width="100%"
          onClick={() => {
            if (canClick.current) {
              const container = document.getElementById("onboarding-container")
              container?.scrollTo({
                left: 0,
                // behavior: screenReaderEnabled.current ? "auto" : "smooth",
                behavior: "auto",
              })
              setCurrentPage(0)

              if (screenReaderEnabled.current) {
                focusElement("onboarding-container")
              }

              canClick.current = false
              setTimeout(() => {
                canClick.current = true
              }, 500)
            }
          }}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          {t("go_back")}
        </Button>
      </FadeFromBottom>
      {/* missing alert */}
      <Alert
        open={missingAlertOpen}
        title={t("something_is_missing")}
        description={t("consents_warning")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setMissingAlertOpen(false)
        }}
      />
      {/* full screen alerts for terms and privacy */}
      <AlertFullScreen
        open={termsAlertOpen}
        setOpen={setTermsAlertOpen}
        content={termsAndConditionsIT}
      />
      <AlertFullScreen
        open={privacyAlertOpen}
        setOpen={setPrivacyAlertOpen}
        content={privacyPolicyIT}
      />
    </Stack>
  )
}

export default OnboardingMobile
