import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import ChallengeMobile from "../../components/challenge/ChallengeMobile"
import ChallengeDesktop from "../../components/challenge/ChallengeDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Challenge = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("challenge")
  }, [])

  return isMobile ? <ChallengeMobile /> : <ChallengeDesktop />
}

export default Challenge
