import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { colors } from "../../../services/config/colors"
import { FootprintContext } from "../../../controllers/footprint"
import { PulseLoader } from "react-spinners"
import MainCategory from "../../../models/mainCategory"
import { TagsContext } from "../../../controllers/tags"
import { FootprintSector } from "../../../services/config/enum"

const FootprintCategoryCardDesktop = ({
  mainCategory,
  sector,
  value,
  givenAnswers,
  totalAnswers,
}: {
  mainCategory: MainCategory
  sector: FootprintSector
  value?: number
  givenAnswers: number
  totalAnswers: number
}) => {
  const { t } = useTranslation()
  const { gettingFootprint } = useContext(FootprintContext)
  const { categories } = useContext(TagsContext)

  return (
    <Stack
      style={{
        width: "100%",
        height: "100%",
        borderRadius: 10,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        paddingInline: 12,
        paddingTop: 18,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        style={{ width: "100%", height: 27, gap: 8, position: "relative" }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: "100%",
            backgroundColor: mainCategory.backTagColor,
          }}
        />
        <Title fontSize={20} lineHeight="27px">
          {mainCategory.name}
        </Title>
        {gettingFootprint ? (
          <PulseLoader
            color={colors.text}
            size={8}
            style={{ position: "absolute", right: 0 }}
          />
        ) : (
          <Text
            fontSize={20}
            fontWeight={500}
            lineHeight="27px"
            style={{ position: "absolute", right: 0 }}
          >
            {value ? value.toString().replace(".", ",") : "--"}
          </Text>
        )}
      </Stack>
      {/* categories */}
      <Stack
        direction="row"
        style={{ width: "100%", height: 40, marginTop: 22, gap: 8 }}
      >
        {categories
          .filter(
            (category) =>
              category.mainCategory &&
              category.mainCategory.id === mainCategory.id
          )
          .map((category) => (
            <div
              key={category.id}
              style={{
                width: "auto",
                height: "100%",
                paddingInline: 8,
                borderRadius: 4,
                backgroundColor: category.backTagColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text fontSize={16} fontWeight={700} color={category.foreColor}>
                {category.name}
              </Text>
            </div>
          ))}
      </Stack>
      {/* answers */}
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 50,
          position: "absolute",
          left: 0,
          bottom: 0,
          backgroundColor: colors.background,
          paddingInline: 12,
        }}
      >
        <Text fontSize={16} fontWeight={500}>
          {givenAnswers}/{totalAnswers} {t("answers")}
        </Text>
        {/* {sector !== "food" &&
        givenAnswers < totalAnswers &&
        formsList.some((item) => item.sector === sector) ? (
          <ButtonBase
            disabled={gettingFootprint}
            disableRipple
            aria-label={t("complete")}
            style={{
              position: "absolute",
              right: 12,
              opacity: gettingFootprint ? 0.4 : 1,
              transition: "150ms",
            }}
            onClick={() => {
              scrollWindowToTop()
              navigate("/performance/footprint/form", {
                state: { sector: sector },
              })
            }}
          >
            <Text
              fontSize={12}
              lineHeight="16px"
              fontWeight={700}
              style={{ textDecoration: "underline" }}
            >
              {t("complete")}
            </Text>
          </ButtonBase>
        ) : null} */}
      </Stack>
    </Stack>
  )
}

export default FootprintCategoryCardDesktop
